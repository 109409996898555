import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import TableHeader from './TableHeaderFrame';
import TableRowFrame from './TableRowFrame';
import Window from '../../../types/Window';

const styles = StyleSheet.create({
    tableContainer: {
        flexDirection: 'row',
        flexWrap: 'wrap',
        marginTop: 10,
        borderWidth: 1,
        borderColor: '#bff0fd',
    },
});

const InvoiceItemsTable: React.FC<{ items: Array<Window> }> = ({ items }) => (
    <View style={styles.tableContainer}>
        <TableHeader />
        <TableRowFrame items={items} />
    </View>
);

export default InvoiceItemsTable;
