import React from "react"
import { Text, View, StyleSheet } from "@react-pdf/renderer"

const borderColor = "#90e5fc"
const styles = StyleSheet.create({
    container: {
        flexDirection: "row",
        borderBottomColor: "#bff0fd",
        backgroundColor: "#bff0fd",
        borderBottomWidth: 1,
        alignItems: "center",
        height: 32,
        textAlign: "center",
        flexGrow: 1,
        fontSize: "10px",
        fontStyle: "bold",
    },
    inputStyle: {
        width: "7.2%",
        borderRightColor: borderColor,
        borderRightWidth: 1,
        backgroundColor: "#ff6a6a",
        height: "100%",
        textAlign: "center",
    },
    louverSize: {
        width: "7.2%",
        borderRightColor: borderColor,
        borderRightWidth: 1,
    },
})

const InvoiceTableHeader = () => (
    <View style={styles.container}>
        <Text style={styles.louverSize}>No</Text>
        <Text style={styles.louverSize}>Location</Text>
        <Text style={styles.inputStyle}>Width Measure</Text>
        <Text style={styles.louverSize}>Width Cut</Text>
        <Text style={styles.louverSize}>Width Fit</Text>
        <Text style={styles.louverSize}>In W In Frame</Text>
        <Text style={styles.inputStyle}>Height Measure</Text>
        <Text style={styles.louverSize}>Height Cut</Text>
        <Text style={styles.louverSize}>Height Fit </Text>
        <Text style={styles.louverSize}> In Height </Text>
        <Text style={styles.inputStyle}> Frame Kind </Text>
        <Text style={styles.inputStyle}> Open </Text>
        <Text style={styles.inputStyle}> Special </Text>
        <Text style={styles.louverSize}> SQ </Text>
    </View>
)

export default InvoiceTableHeader
