import React, { useEffect, useState } from 'react';
import {
    Autocomplete,
    Box,
    Button,
    Grid,
    InputAdornment,
    TextField,
    Typography,
} from '@mui/material';
import Window from '../types/Window';
import SaveIcon from '@mui/icons-material/Save';
import {
    frameDesignOptions,
    frameKinds,
    numberOfPanelOptions,
    panelDesigns,
} from '../pages/constants';
import OptionType from '../types/OptionType';
import CloseIcon from '@mui/icons-material/Close';
import { v4 as uuidv4 } from 'uuid';
import { useUserInfo } from 'context/UserInfoContext';
import WindowDataProcessingUtils from 'pages/utils/WindowDataProcessingUtils';

interface WindowComponentProps {
    window: Window | undefined;
    handleCloseWindowModal: () => void;
    addWindow: (window: Window) => void;
    editWindow: (window: Window) => void;
    isDistributorWindow?: boolean;
}

const WindowComponent: React.FC<WindowComponentProps> = ({
    window = {
        id: '',
        width: 0,
        height: 0,
        louverSize: 3,
        divRail: 0,
        frameKind: null,
        frameDesign: null,
        numberOfPanels: null,
        panelDesign: null,
    },
    handleCloseWindowModal,
    addWindow,
    editWindow,
    isDistributorWindow = true,
}) => {
    const {
        userInfo: { accountType },
    } = useUserInfo();

    const [frameKind, setFrameKind] = React.useState<OptionType | null>(
        window.frameKind || null,
    );

    const [frameDesign, setFrameDesign] = React.useState<OptionType | null>(
        window.frameDesign || null,
    );

    const [numberOfPanels, setNumberOfPanels] =
        React.useState<OptionType | null>(window.numberOfPanels || null);

    const [panelDesign, setPanelDesign] = React.useState<OptionType | null>(
        window.panelDesign || null,
    );

    const [width, setWidth] = useState<string>(String(window.width || ''));

    const [height, setHeight] = useState<string>(String(window.height || ''));

    const [divRail, setDivRail] = useState<string>(
        String(window.divRail || ''),
    );

    const [location, setLocation] = useState<string>(window.location || '');

    const [price, setPrice] = useState<string>(String(window.price || ''));

    const [special, setSpecial] = useState<string>(window.special || '');

    const [derivedState, setDerivedState] = useState<any>({
        widthCut: '',
        widthFit: '',
        panelHeight: '',
        totalLouvers: '',
        heightCut: '',
        heightFit: '',
        heightInFrame: '',
        louverWidth: '',
        topAndBottomRailWidth: '',
        panelWidth: '',
        widthInFrame: '',
        frameMaterialType: '',
        stileMaterial: '',
        bottomRailWidthMaterial: '',
        topRailWidthMaterial: '',
        louverMaterial: '',
        magicTiltMaterial: '',
        cutAluminumMaterial: '',
        sqMaterial: '',
        bottomRailHeight: '',
        bottomLouvers: '',
        topRailHeight: '',
        topLouvers: '',
        dividerRailMaterial: '',
        tPodMaterial: '',
        price: '',
    });

    const createFrameMaterialTypes = (frameMaterialType) => {
        if (!frameMaterialType) return <></>;

        return Object.keys(frameMaterialType)
            .filter((key) => frameMaterialType[key])
            .map((key) => (
                <Grid key={key} item xs={12} sm={1.5}>
                    <TextField
                        id={key}
                        label={`${key} Material (ft)`}
                        value={WindowDataProcessingUtils.roundUp(
                            frameMaterialType[key],
                            2,
                        )}
                        variant="standard"
                        name={key}
                    />
                </Grid>
            ));
    };

    const getDerivedWindowValues = (
        width = null,
        height = null,
        frameDesign = null,
        frameKind = null,
        numberOfPanels = null,
        panelDesign = null,

        bottomAndTopRailHeightDependency = 'default',

        currentBottomAndTopRailHeightSpec = {
            bottomRailHeight: '',
            bottomLouvers: '',
            topRailHeight: '',
            topLouvers: '',
        },
    ) => {
        if (!(width && height && frameDesign && frameKind && panelDesign))
            return {
                widthCut: '',
                widthFit: '',
                panelHeight: '',
                totalLouvers: '',
                heightCut: '',
                heightFit: '',
                heightInFrame: '',
                louverWidth: '',
                topAndBottomRailWidth: '',
                panelWidth: '',
                widthInFrame: '',
                frameMaterialType: '',
                stileMaterial: '',
                bottomRailWidthMaterial: '',
                topRailWidthMaterial: '',
                louverMaterial: '',
                magicTiltMaterial: '',
                cutAluminumMaterial: '',
                sqMaterial: '',
                bottomRailHeight: '',
                bottomLouvers: '',
                topRailHeight: '',
                topLouvers: '',
                dividerRailMaterial: '',
                tPodMaterial: '',
                price: '',
            };

        const deltaObject = WindowDataProcessingUtils.getDeltaBasedOnFrameType(
            frameKind.name,
        );

        const convertedWidth = Number(
            WindowDataProcessingUtils.convertingFractionToNumber(width),
        );

        const widthCut = WindowDataProcessingUtils.computeWidthCut(
            convertedWidth,
            deltaObject.widthDeltaCut,
        );

        const widthFit = WindowDataProcessingUtils.computeWidthFit(
            convertedWidth,
            deltaObject.widthDeltaFit,
        );

        const widthInFrame = WindowDataProcessingUtils.computeWidthInFrame(
            convertedWidth,
            deltaObject.widthDeltaInFrame,
        );

        const panelWidth = WindowDataProcessingUtils.computePanelWidth(
            convertedWidth,
            numberOfPanels.id,
            deltaObject.widthDeltaInFrame,
            panelDesign.value,
        );

        const topAndBottomRailWidth =
            WindowDataProcessingUtils.computeTopAndBottomRailWidth(
                convertedWidth,
                numberOfPanels.id,
                deltaObject.widthDeltaInFrame,
                panelDesign.value,
            );

        const louverWidth = WindowDataProcessingUtils.computeLouverWidth(
            convertedWidth,
            numberOfPanels.id,
            deltaObject.widthDeltaInFrame,
            panelDesign.value,
        );

        const convertedHeight = Number(
            WindowDataProcessingUtils.convertingFractionToNumber(height),
        );

        const convertedDivRail = Number(
            WindowDataProcessingUtils.convertingFractionToNumber(divRail),
        );

        const heightCut = WindowDataProcessingUtils.computeHeightCut(
            convertedHeight,
            deltaObject.heightDeltaCut,
        );

        const heightFit = WindowDataProcessingUtils.computeHeightFit(
            convertedHeight,
            deltaObject.heightDeltaFit,
        );

        const heightInFrame = WindowDataProcessingUtils.computeHeightInFrame(
            convertedHeight,
            deltaObject.heightDeltaInFrame,
        );

        const panelHeight = WindowDataProcessingUtils.computePanelHeight(
            convertedHeight,
            deltaObject.heightDeltaInFrame,
        );

        let bottomAndTopRailHeightSpec;

        if (bottomAndTopRailHeightDependency === 'default') {
            bottomAndTopRailHeightSpec =
                WindowDataProcessingUtils.computeTopAndBottomRailWithHeightAndDivRail(
                    convertedHeight,
                    convertedDivRail,
                    deltaObject
                );
        } else if (bottomAndTopRailHeightDependency === 'bottomRailHeight') {
            bottomAndTopRailHeightSpec =
                WindowDataProcessingUtils.computeTopAndBottomRailWithBottomRailHeight(
                    convertedHeight,
                    convertedDivRail,
                    deltaObject,
                    Number(
                        WindowDataProcessingUtils.convertingFractionToNumber(
                            currentBottomAndTopRailHeightSpec.bottomRailHeight,
                        ),
                    ),
                    numberOfPanels.value,
                    WindowDataProcessingUtils.transformFractionToNumber(
                        derivedState,
                    ),
                );
        } else if (bottomAndTopRailHeightDependency === 'bottomLouvers') {
            bottomAndTopRailHeightSpec =
                WindowDataProcessingUtils.computeTopAndBottomRailWithBottomLouvers(
                    convertedHeight,
                    convertedDivRail,
                    deltaObject,
                    Number(
                        WindowDataProcessingUtils.convertingFractionToNumber(
                            currentBottomAndTopRailHeightSpec.bottomLouvers,
                        ),
                    ),
                    WindowDataProcessingUtils.transformFractionToNumber(
                        derivedState,
                    ),
                );
        } else if (bottomAndTopRailHeightDependency === 'topRailHeight') {
            bottomAndTopRailHeightSpec =
                WindowDataProcessingUtils.computeTopAndBottomRailWithTopRailHeight(
                    convertedHeight,
                    convertedDivRail,
                    deltaObject,
                    Number(
                        WindowDataProcessingUtils.convertingFractionToNumber(
                            currentBottomAndTopRailHeightSpec.topRailHeight,
                        ),
                    ),
                    WindowDataProcessingUtils.transformFractionToNumber(
                        derivedState,
                    ),
                );
        } else if (bottomAndTopRailHeightDependency === 'topLouvers') {
            bottomAndTopRailHeightSpec =
                WindowDataProcessingUtils.computeTopAndBottomRailWithTopLouvers(
                    convertedHeight,
                    convertedDivRail,
                    deltaObject,
                    Number(
                        WindowDataProcessingUtils.convertingFractionToNumber(
                            currentBottomAndTopRailHeightSpec.topLouvers,
                        ),
                    ),
                    WindowDataProcessingUtils.transformFractionToNumber(
                        derivedState,
                    ),
                );
        } else {
            bottomAndTopRailHeightSpec = currentBottomAndTopRailHeightSpec;
        }

        Object.keys(bottomAndTopRailHeightSpec).forEach(
            (key) =>
                (bottomAndTopRailHeightSpec[key] =
                    WindowDataProcessingUtils.convertingNumberToFraction(
                        bottomAndTopRailHeightSpec[key],
                    )),
        );

        const totalLouvers =
            (Number(bottomAndTopRailHeightSpec.bottomLouvers) +
                Number(bottomAndTopRailHeightSpec.topLouvers)) *
            numberOfPanels.id;

        const totalValues = WindowDataProcessingUtils.computeDerivedValues(
            convertedWidth,
            widthCut,
            deltaObject.widthDeltaInFrame,
            deltaObject.heightDeltaInFrame,
            convertedHeight,
            heightCut,
            frameDesign,
            panelHeight,
            numberOfPanels,
            panelDesign,
            totalLouvers,
            louverWidth,
            topAndBottomRailWidth,
        );

        // if (!divRail) totalLouvers.dividerRailMaterial = 0;

        const topRailHeight = Number(
            WindowDataProcessingUtils.convertingFractionToNumber(
                bottomAndTopRailHeightSpec.topRailHeight,
            ),
        );
        const bottomRailHeight = Number(
            WindowDataProcessingUtils.convertingFractionToNumber(
                bottomAndTopRailHeightSpec.bottomRailHeight,
            ),
        );

        let fiveInchMaterial = 0,
            threeInchMaterial = 0;

        if (topRailHeight > 3.5) {
            fiveInchMaterial += totalValues.topRailWidthMaterial;
        } else {
            threeInchMaterial += totalValues.topRailWidthMaterial;
        }

        if (bottomRailHeight > 3.5) {
            fiveInchMaterial += totalValues.bottomRailWidthMaterial;
        } else {
            threeInchMaterial += totalValues.bottomRailWidthMaterial;
        }

        fiveInchMaterial = WindowDataProcessingUtils.roundUp(
            fiveInchMaterial,
            1,
        );

        threeInchMaterial = WindowDataProcessingUtils.roundUp(
            threeInchMaterial,
            1,
        );

        const returnObject = {
            widthCut:
                WindowDataProcessingUtils.convertingNumberToFraction(widthCut),
            widthFit:
                WindowDataProcessingUtils.convertingNumberToFraction(widthFit),
            widthInFrame:
                WindowDataProcessingUtils.convertingNumberToFraction(
                    widthInFrame,
                ),
            panelHeight:
                WindowDataProcessingUtils.convertingNumberToFraction(
                    panelHeight,
                ),
            totalLouvers,
            heightCut:
                WindowDataProcessingUtils.convertingNumberToFraction(heightCut),
            heightFit:
                WindowDataProcessingUtils.convertingNumberToFraction(heightFit),
            heightInFrame:
                WindowDataProcessingUtils.convertingNumberToFraction(
                    heightInFrame,
                ),
            louverWidth:
                WindowDataProcessingUtils.convertingNumberToFraction(
                    louverWidth,
                ),
            topAndBottomRailWidth:
                WindowDataProcessingUtils.convertingNumberToFraction(
                    topAndBottomRailWidth,
                ),
            panelWidth:
                WindowDataProcessingUtils.convertingNumberToFraction(
                    panelWidth,
                ),
            ...totalValues,
            ...bottomAndTopRailHeightSpec,
            fiveInchMaterial,
            threeInchMaterial,
        };

        if (bottomAndTopRailHeightDependency === 'default') {
            return returnObject;
        } else {
            return {
                ...returnObject,
                [bottomAndTopRailHeightDependency]:
                    currentBottomAndTopRailHeightSpec[
                        bottomAndTopRailHeightDependency
                    ],
            };
        }
    };

    useEffect(() => {
        setDerivedState(
            getDerivedWindowValues(
                width,
                height,
                frameDesign,
                frameKind,
                numberOfPanels,
                panelDesign,
            ),
        );
    }, [
        width,
        height,
        frameDesign,
        frameKind,
        numberOfPanels,
        panelDesign,
        divRail,
    ]);

    const handleSavingRow = () => {
        const inputState = {
            id: window.id ? window.id : uuidv4(),
            width,
            height,
            frameKind,
            frameDesign,
            numberOfPanels,
            panelDesign,
            divRail,
            location,
            special,
            louverSize: 3,
            price,
            ...derivedState,
        };
        if (window.id) {
            editWindow(inputState);
        } else {
            addWindow(inputState);
        }
        handleCloseWindowModal();
    };

    const defaultFrameKindProps = {
        options: frameKinds,
        getOptionLabel: (option: OptionType) => option.name,
    };

    const defaultFrameDesignProps = {
        options: frameKind ? frameDesignOptions[frameKind.id] : [],
        getOptionLabel: (option: OptionType) => option.name,
    };

    const defaultNumberOfPanels = {
        options: numberOfPanelOptions,
        getOptionLabel: (option: OptionType) => option.name,
    };

    const defaultPanelDesigns = {
        options: numberOfPanels ? panelDesigns[numberOfPanels.id] : [],
        getOptionLabel: (option: OptionType) => option.name,
    };

    return (
        <Grid container display="flex" sx={{ magrin: 'auto' }} height="100%">
            <Box
                sx={{
                    width: '80%',
                    height: '90%',
                    m: 'auto',
                    overflowY: 'auto',
                }}
            >
                <Typography variant="subtitle1" gutterBottom fontWeight={700}>
                    Input Window Dimension
                </Typography>
                <Grid
                    item
                    xs={12}
                    sx={{ marginBottom: 2, fontSize: '1.6em' }}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                >
                    <Grid item xs={12} sm={4}>
                        <Autocomplete
                            {...defaultFrameKindProps}
                            id="frameKind"
                            value={frameKind}
                            onChange={(
                                event: unknown,
                                newValue: OptionType | null,
                            ) => {
                                setFrameKind(newValue);
                                setFrameDesign(null);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Frame Kind"
                                    variant="standard"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={5}>
                        <Autocomplete
                            {...defaultFrameDesignProps}
                            id="frameDesign"
                            value={frameDesign}
                            onChange={(
                                event: unknown,
                                newValue: OptionType | null,
                            ) => {
                                setFrameDesign(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Frame Design"
                                    variant="standard"
                                />
                            )}
                        />
                    </Grid>
                </Grid>
                <Grid container spacing={1} sx={{ marginBottom: 2 }}>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            id="width"
                            label="Width (in)"
                            variant="standard"
                            name="width"
                            value={width !== '0' ? width : ''}
                            onChange={(e) => setWidth(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            id="height"
                            label="Height (in)"
                            variant="standard"
                            name="height"
                            value={height !== '0' ? height : ''}
                            onChange={(e) => setHeight(e.target.value)}
                            required
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Autocomplete
                            {...defaultNumberOfPanels}
                            id="controlled-demo"
                            value={numberOfPanels}
                            onChange={(
                                event: unknown,
                                newValue: OptionType | null,
                            ) => {
                                setNumberOfPanels(newValue);
                                setPanelDesign(null);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Number of Panels"
                                    variant="standard"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <Autocomplete
                            {...defaultPanelDesigns}
                            value={panelDesign}
                            onChange={(
                                event: unknown,
                                newValue: OptionType | null,
                            ) => {
                                setPanelDesign(newValue);
                            }}
                            renderInput={(params) => (
                                <TextField
                                    {...params}
                                    label="Panel Design"
                                    variant="standard"
                                />
                            )}
                        />
                    </Grid>
                    <Grid item xs={12} sm={2}>
                        <TextField
                            id="divRail"
                            label="Div Rail (in)"
                            variant="standard"
                            name="divRail"
                            value={divRail !== '0' ? divRail : ''}
                            onChange={(e) => setDivRail(e.target.value)}
                        />
                    </Grid>
                </Grid>
                <Grid
                    item
                    xs={12}
                    container
                    direction="row"
                    justifyContent="flex-start"
                    alignItems="center"
                    spacing={2}
                    sx={{ mb: '30px' }}
                >
                    <Grid item xs={12} sm={3}>
                        <TextField
                            id="price"
                            label="Price"
                            variant="standard"
                            name="price"
                            value={price || ''}
                            onChange={(e) => setPrice(e.target.value)}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        $
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            id="location"
                            label="Location"
                            variant="standard"
                            name="location"
                            value={location || ''}
                            onChange={(e) => setLocation(e.target.value)}
                        />
                    </Grid>
                    <Grid item xs={12} sm={3}>
                        <TextField
                            id="special"
                            label="Special"
                            variant="standard"
                            name="special"
                            value={special || ''}
                            onChange={(e) => setSpecial(e.target.value)}
                        />
                    </Grid>
                </Grid>
                {isDistributorWindow && (
                    <React.Fragment>
                        <Typography
                            variant="subtitle1"
                            gutterBottom
                            fontWeight={700}
                        >
                            Adjustable Calculated Window Dimension
                        </Typography>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            fontWeight={700}
                        >
                            Width Dimension
                        </Typography>
                        <Grid container spacing={3} sx={{ mb: '30px' }}>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="widthCut"
                                    label="Width Cut (in)"
                                    variant="standard"
                                    name="widthCut"
                                    value={derivedState.widthCut || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="widthFit"
                                    label="Width Fit (in)"
                                    variant="standard"
                                    name="widthFit"
                                    value={derivedState.widthFit || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="widthInFrame"
                                    label="Width In Frame (in)"
                                    variant="standard"
                                    name="widthInFrame"
                                    value={derivedState.widthInFrame || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="panelWidth"
                                    label="Panel Width (in)"
                                    variant="standard"
                                    name="panelWidth"
                                    value={derivedState.panelWidth || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="topRailWidth"
                                    label="Top & Bottom Rail Width (in)"
                                    variant="standard"
                                    name="topRailWidth"
                                    value={
                                        derivedState.topAndBottomRailWidth || ''
                                    }
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="dealer"
                                    label="Louver Width (in)"
                                    variant="standard"
                                    name="dealer"
                                    value={derivedState.louverWidth || ''}
                                />
                            </Grid>
                        </Grid>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            fontWeight={700}
                        >
                            Height Dimension
                        </Typography>
                        <Grid container spacing={3} sx={{ mb: '30px' }}>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="heightCut"
                                    label="Height Cut (in)"
                                    variant="standard"
                                    name="heightCut"
                                    value={derivedState.heightCut || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="heightFit"
                                    label="Height Fit (in)"
                                    variant="standard"
                                    name="heightFit"
                                    value={derivedState.heightFit || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="heightInFrame"
                                    label="Height In Frame (in)"
                                    variant="standard"
                                    name="heightInFrame"
                                    value={derivedState.heightInFrame || ''}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="panelHeight"
                                    label="Panel Height (in)"
                                    variant="standard"
                                    name="panelHeight"
                                    value={derivedState.panelHeight || ''}
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3}>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="bottomRailHeight"
                                    label="Bottom Rail Height (in)"
                                    variant="standard"
                                    name="bottomRailHeight"
                                    value={derivedState.bottomRailHeight || ''}
                                    onChange={(e) => {
                                        console.log('change here');
                                        setDerivedState(
                                            getDerivedWindowValues(
                                                width,
                                                height,
                                                frameDesign,
                                                frameKind,
                                                numberOfPanels,
                                                panelDesign,
                                                'bottomRailHeight',
                                                {
                                                    bottomRailHeight:
                                                        e.target.value,
                                                    topRailHeight:
                                                        derivedState.topRailHeight,
                                                    bottomLouvers:
                                                        derivedState.bottomLouvers,
                                                    topLouvers:
                                                        derivedState.topLouvers,
                                                },
                                            ),
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="bottomLouvers"
                                    label="# Bottom Louvers"
                                    variant="standard"
                                    name="bottomLouvers"
                                    value={derivedState.bottomLouvers || ''}
                                    onChange={(e) => {
                                        setDerivedState(
                                            getDerivedWindowValues(
                                                width,
                                                height,
                                                frameDesign,
                                                frameKind,
                                                numberOfPanels,
                                                panelDesign,

                                                'bottomLouvers',

                                                {
                                                    bottomRailHeight:
                                                        derivedState.bottomRailHeight,
                                                    topRailHeight:
                                                        derivedState.topRailHeight,
                                                    bottomLouvers:
                                                        e.target.value,
                                                    topLouvers:
                                                        derivedState.topLouvers,
                                                },
                                            ),
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="topRailHeight"
                                    label="Top Rail Height (in)"
                                    variant="standard"
                                    name="topRailHeight"
                                    value={derivedState.topRailHeight || ''}
                                    onChange={(e) => {
                                        setDerivedState(
                                            getDerivedWindowValues(
                                                width,
                                                height,
                                                frameDesign,
                                                frameKind,
                                                numberOfPanels,
                                                panelDesign,

                                                'topRailHeight',

                                                {
                                                    bottomRailHeight:
                                                        derivedState.bottomRailHeight,
                                                    topRailHeight:
                                                        e.target.value,
                                                    bottomLouvers:
                                                        derivedState.bottomLouvers,
                                                    topLouvers:
                                                        derivedState.topLouvers,
                                                },
                                            ),
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="topLouvers"
                                    label="# Top Louvers"
                                    variant="standard"
                                    name="topLouvers"
                                    value={derivedState.topLouvers || ''}
                                    onChange={(e) => {
                                        setDerivedState(
                                            getDerivedWindowValues(
                                                width,
                                                height,
                                                frameDesign,
                                                frameKind,
                                                numberOfPanels,
                                                panelDesign,

                                                'topLouvers',

                                                {
                                                    bottomRailHeight:
                                                        derivedState.bottomRailHeight,
                                                    topRailHeight:
                                                        derivedState.topRailHeight,
                                                    bottomLouvers:
                                                        derivedState.bottomLouvers,
                                                    topLouvers: e.target.value,
                                                },
                                            ),
                                        );
                                    }}
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="totalLouvers"
                                    label="# Total Louvers"
                                    variant="standard"
                                    name="totalLouvers"
                                    value={derivedState.totalLouvers || ''}
                                />
                            </Grid>
                        </Grid>
                        <Typography
                            variant="subtitle2"
                            gutterBottom
                            fontWeight={700}
                            sx={{ mt: '30px' }}
                        >
                            Total Materials
                        </Typography>
                        <Grid container spacing={3} sx={{ mb: '30px' }}>
                            <Grid item xs={12} sm={1.5}>
                                <TextField
                                    id="sq"
                                    label="SQ"
                                    value={derivedState.sqMaterial || ''}
                                    variant="standard"
                                    name="dealer"
                                />
                            </Grid>
                            <Grid item xs={12} sm={1.5}>
                                <TextField
                                    id="stile"
                                    label="Stile (ft)"
                                    value={derivedState.stileMaterial || ''}
                                    variant="standard"
                                    name="stile"
                                />
                            </Grid>
                            <Grid item xs={12} sm={1.5}>
                                <TextField
                                    id="tPod"
                                    label="T Pod (ft)"
                                    value={derivedState.tPodMaterial || ''}
                                    variant="standard"
                                    name="tPod"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="bottomRailHeight"
                                    label={`${
                                        derivedState.bottomRailHeight &&
                                        WindowDataProcessingUtils.convertingFractionToNumber(
                                            String(
                                                derivedState.bottomRailHeight,
                                            ),
                                        ) > 3.5
                                            ? 5.5
                                            : 3.5
                                    } Bottom Rail (ft)`}
                                    value={
                                        derivedState.bottomRailWidthMaterial ||
                                        ''
                                    }
                                    variant="standard"
                                    name="bottomRailHeight"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="topRailHeight"
                                    label={`${
                                        derivedState.topRailHeight &&
                                        WindowDataProcessingUtils.convertingFractionToNumber(
                                            String(derivedState.topRailHeight),
                                        ) > 3.5
                                            ? 5.5
                                            : 3.5
                                    } Top Rail (ft)`}
                                    value={
                                        derivedState.topRailWidthMaterial || ''
                                    }
                                    variant="standard"
                                    name="topRailHeight"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ mb: '30px' }}>
                            <Grid item xs={12} sm={1.5}>
                                <TextField
                                    id="louverMaterial"
                                    label="Louver Material (ft)"
                                    value={derivedState.louverMaterial || ''}
                                    variant="standard"
                                    name="louverMaterial"
                                />
                            </Grid>
                            {!!divRail && (
                                <Grid item xs={12} sm={1.5}>
                                    <TextField
                                        id="divRailMaterial"
                                        label="Divider Rail (ft)"
                                        value={
                                            derivedState.dividerRailMaterial ||
                                            ''
                                        }
                                        variant="standard"
                                        name="divRailMaterial"
                                    />
                                </Grid>
                            )}
                            <Grid item xs={12} sm={1.5}>
                                <TextField
                                    id="magicTiltMaterial"
                                    label="Magic Tilt (ft)"
                                    value={derivedState.magicTiltMaterial || ''}
                                    variant="standard"
                                    name="magicTiltMaterial"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="cutAluminumMaterial"
                                    label="Cut Aluminum (ft)"
                                    value={
                                        derivedState.cutAluminumMaterial || ''
                                    }
                                    variant="standard"
                                    name="cutAluminumMaterial"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="threeInchMaterial"
                                    label="3.5 Rail Width (ft)"
                                    value={derivedState.threeInchMaterial || ''}
                                    variant="standard"
                                    name="threeInchMaterial"
                                />
                            </Grid>
                            <Grid item xs={12} sm={2}>
                                <TextField
                                    id="fiveInchMaterial"
                                    label="5.5 Rail Width (ft)"
                                    value={derivedState.fiveInchMaterial || ''}
                                    variant="standard"
                                    name="fiveInchMaterial"
                                />
                            </Grid>
                        </Grid>
                        <Grid container spacing={3} sx={{ mb: '30px' }}>
                            {createFrameMaterialTypes(
                                derivedState.frameMaterialType,
                            )}
                        </Grid>
                    </React.Fragment>
                )}
            </Box>

            <Grid
                container
                display="flex"
                justifyContent={'space-between'}
                sx={{
                    margin: 'auto',
                    width: '70%',
                }}
            >
                <Box>
                    <Button
                        variant="outlined"
                        startIcon={<CloseIcon />}
                        size="large"
                        onClick={handleCloseWindowModal}
                    >
                        Close
                    </Button>
                </Box>
                <Box>
                    <Button
                        variant="outlined"
                        startIcon={<SaveIcon />}
                        size="large"
                        onClick={handleSavingRow}
                        disabled={
                            !(
                                frameKind &&
                                frameDesign &&
                                width &&
                                height &&
                                numberOfPanels &&
                                panelDesign
                            )
                        }
                    >
                        Save
                    </Button>
                </Box>
            </Grid>
        </Grid>
    );
};

export default WindowComponent;
