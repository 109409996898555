/* eslint-disable react/no-unescaped-entities */
import React, { useReducer } from 'react';
import { Box, Button, useTheme, ButtonGroup } from '@mui/material';
import { doc, updateDoc } from 'firebase/firestore';
import { useUserInfo } from 'context/UserInfoContext';
import { orderReducer } from '../constants';
import Order from 'types/Order';
import CloseIcon from '@mui/icons-material/Close';
import SaveIcon from '@mui/icons-material/Save';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useMutation, useQueryClient } from 'react-query';
import { firestore } from '../../firebaseSetUp/firebaseSetUp';
import InvoiceGeneratorModal from 'components/InvoiceGenerator/InvoiceGenerator';
import { initialInvoice } from 'components/InvoiceGenerator/data/initialData';
import { ProductLine } from 'components/InvoiceGenerator/data/types';
import PrintIcon from '@mui/icons-material/Print';
import OrderBasicInfoComponent from 'components/OrderBasicInfoComponent';
import OrderWindowsComponent from 'components/OrderWindowsComponent';
import PrintModalComponent from 'components/PrintModalComponent';
import DashboardManager from 'Manager';
import TotalMaterialComponent from 'components/TotalMaterialComponent';
import WindowDataProcessingUtils from 'pages/utils/WindowDataProcessingUtils';

interface OrderComponentProps {
    defaultOrder: Order;
    handleCloseModal: () => void;
}

const OrderComponent: React.FC<OrderComponentProps> = ({
    defaultOrder,
    handleCloseModal = () => null,
}): JSX.Element => {
    const [order, setOrder] = useReducer(orderReducer, defaultOrder);
    const theme = useTheme();

    const { userInfo: currentUser } = useUserInfo();

    const [openPrintPDF, setOpenPrintPDF] = React.useState(false);
    const handleOpenPrintPDF = () => setOpenPrintPDF(true);
    const handleClosePrintPDF = () => setOpenPrintPDF(false);

    const [openInvoiceGenerator, setOpenInvoiceGenerator] =
        React.useState(false);
    const handleOpenInvoiceGenerator = () => setOpenInvoiceGenerator(true);
    const handleCloseInvoiceGenerator = () => setOpenInvoiceGenerator(false);
    const createInvoice = (order) => {
        if (!order) return initialInvoice;
        if (!currentUser.address) return initialInvoice;
        if (!order.dealer) return initialInvoice;

        const logo = '';
        const logoWidth = 0;
        const title = 'INVOICE';
        const companyName = `${currentUser.name}`;
        const name = `${currentUser.name}`;
        const companyAddress = `${currentUser.address.streetName}`;
        const companyAddress2 = `${currentUser.address.city}, ${currentUser.address.state} ${currentUser.address.zipCode}`;
        const companyCountry = 'United States';

        const billTo = 'Bill To:';
        const clientName = `${order.dealer.name}`;
        const clientAddress = `${order.dealer.streetName}`;
        const clientAddress2 = `${order.dealer.city}, ${order.dealer.state} ${order.dealer.zipCode}`;
        const clientCountry = 'United States';

        const invoiceTitleLabel = 'Invoice#';
        const invoiceTitle = `${order.orderId}`;
        const invoiceDateLabel = 'Invoice Date';
        const invoiceDate = `${order.date}`;
        const invoiceDueDateLabel = 'Due Date';
        const invoiceDueDate = '';

        const productLineDescription = 'Item Description';
        const productLineQuantity = '';
        const productLineQuantityRate = '';
        const productLineQuantityAmount = '';

        const productLines: ProductLine[] = [];

        for (const window of order.windows) {
            const description = window.id;
            const quantity = '1';
            const rate = window.price;

            productLines.push({
                description,
                quantity,
                rate,
            });
        }

        const subTotalLabel = 'Sub Total';
        const taxLabel = '';

        const totalLabel = 'TOTAL';
        const currency = '$';

        const notesLabel = '';
        const notes = 'Notes';
        const termLabel = 'Terms & Conditions';
        const term = '';

        return {
            logo,
            logoWidth,
            title,
            companyName,
            name,
            companyAddress,
            companyAddress2,
            companyCountry,
            billTo,
            clientName,
            clientAddress,
            clientAddress2,
            clientCountry,
            invoiceTitleLabel,
            invoiceTitle,
            invoiceDateLabel,
            invoiceDate,
            invoiceDueDateLabel,
            invoiceDueDate,
            productLineDescription,
            productLineQuantity,
            productLineQuantityRate,
            productLineQuantityAmount,
            productLines,
            subTotalLabel,
            taxLabel,
            totalLabel,
            currency,
            notesLabel,
            notes,
            termLabel,
            term,
        };
    };

    const queryClient = useQueryClient();

    const ordersMutation = useMutation(
        async (order: any) => {
            try {
                if (order.id) {
                    await updateDoc(doc(firestore, 'orders', order.id), {...order, updatedAt: new Date().toISOString()});
                } else {
                    await DashboardManager.saveOrder(order);
                }
            } catch (e) {
                console.error('Error adding document: ', e);
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['orders']);
                handleCloseModal();
            },
        },
    );

    const saveOrder = (order: Order) => {
        ordersMutation.mutate(order);
    };

    return (
        <Box sx={{ height: '100%', display:'flex', flexDirection: 'column' }}>
            <ButtonGroup variant="outlined" aria-label="text button group">
                <Button
                    variant="outlined"
                    startIcon={<PrintIcon />}
                    onClick={handleOpenPrintPDF}
                >
                    Print Windows
                </Button>
                <Button
                    variant="outlined"
                    startIcon={<PrintIcon />}
                    onClick={handleOpenInvoiceGenerator}
                >
                    Print Invoice
                </Button>
            </ButtonGroup>
            <Box
                sx={{
                    overflow: 'auto',
                    height: '75%',
                    marginTop: '20px',
                    marginBottom: '15px',
                }}
            >
                <Box
                    sx={{
                        '& .MuiDataGrid-cell--editing': {
                            bgcolor: 'rgb(255,215,115, 0.19)',
                            color: '#1a3e72',
                        },
                        '& .Mui-error': {
                            bgcolor: (theme) =>
                                `rgb(126,10,15, ${
                                    theme.palette.mode === 'dark' ? 0 : 0.1
                                })`,
                            color: (theme) =>
                                theme.palette.mode === 'dark'
                                    ? '#ff4343'
                                    : '#750f0f',
                        },
                        width: '100%',
                        height: '90%',
                    }}
                >
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                backgroundColor: `${theme.palette.alternate.main}`,
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                <b> Total Material </b>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                backgroundColor: `${theme.palette.alternate.main}`,
                            }}
                        >
                            <TotalMaterialComponent totalMaterial={WindowDataProcessingUtils.computeTotalMaterial(order.windows)}></TotalMaterialComponent>
                        </AccordionDetails>
                    </Accordion>
                    <Accordion defaultExpanded={true}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                backgroundColor: `${theme.palette.alternate.main}`,
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                <b> Basic Information</b>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                backgroundColor: `${theme.palette.alternate.main}`,
                            }}
                        >
                            <OrderBasicInfoComponent
                                order={order}
                                setOrder={setOrder}
                            ></OrderBasicInfoComponent>
                        </AccordionDetails>
                    </Accordion>

                    <Accordion
                        sx={{
                            backgroundColor: `${theme.palette.alternate.main}`,
                        }}
                        defaultExpanded={true}
                    >
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1a-content"
                            id="panel1a-header"
                            sx={{
                                backgroundColor: `${theme.palette.alternate.main}`,
                            }}
                        >
                            <Typography variant="h5" gutterBottom>
                                <b> Edit Windows </b>
                            </Typography>
                        </AccordionSummary>
                        <AccordionDetails
                            sx={{
                                backgroundColor: `${theme.palette.alternate.main}`,
                            }}
                        >
                            <OrderWindowsComponent
                                order={order}
                                setOrder={setOrder}
                            ></OrderWindowsComponent>
                        </AccordionDetails>
                    </Accordion>
                </Box>
            </Box>
            <Box
                sx={{
                    margin: 'auto',
                    width: '70%',
                    display: 'flex',
                    justifyContent: 'space-between',
                }}
            >
                <Box>
                    <Button
                        variant="contained"
                        startIcon={<CloseIcon />}
                        size="large"
                        onClick={handleCloseModal}
                    >
                        Close
                    </Button>
                </Box>
                <Box>
                    <Button
                        variant="contained"
                        startIcon={<SaveIcon />}
                        size="large"
                        onClick={() => saveOrder(order)}
                        disabled={ordersMutation.isLoading}
                    >
                        Save
                    </Button>
                </Box>
            </Box>

            <PrintModalComponent
                open={openPrintPDF}
                handleClose={handleClosePrintPDF}
                order={order}
            />

            <InvoiceGeneratorModal
                open={openInvoiceGenerator}
                handleClose={handleCloseInvoiceGenerator}
                invoice={createInvoice(order)}
            ></InvoiceGeneratorModal>
        </Box>
    );
};

export default OrderComponent;
