import React, { ReactNode, useContext, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useQuery } from 'react-query';
import Manager from 'Manager';
import User, { AccountType } from 'types/User';

export interface UserContextInfo {
    userInfo: User;
    parentInfo?: User;
}

const defaultState: UserContextInfo = {
    userInfo: {
        accountType: AccountType.DEALER,
        address: {
            streetName: '',
            city: '',
            state: '',
            zipCode: '',
        },
        distributorId: '',
        parentId: '0',
        id: '',
        name: '',
        email: '',
    },
};

interface UserInfoProviderProps {
    children?: ReactNode;
}

const UserInfoContext = React.createContext<UserContextInfo>(defaultState);

export const useUserInfo = () => {
    return useContext(UserInfoContext);
};

export const UserInfoProvider: React.FC<UserInfoProviderProps> = ({
    children,
}) => {
    const { currentUser } = useAuth();

    const { isLoading: isUserInfoLoading, data: userInfo } = useQuery(
        'userInfo',
        async () => {
            return (await Manager.getUserMetaData(currentUser.uid)) as User;
        },
    );

    const { isLoading: isDistributorLoading, data: parentInfo } = useQuery(
        'parentInfo',
        async () => {
            return (await Manager.getDistributor(currentUser.uid));
        },
    );

    const value = { userInfo, parentInfo };

    return (
        <UserInfoContext.Provider value={value}>
            {!isUserInfoLoading && !isDistributorLoading && children}
        </UserInfoContext.Provider>
    );
};
