import { LicenseInfo } from '@mui/x-data-grid-pro';
import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import Page from 'components/Page';
import { SnackbarProvider } from 'notistack';
import { alpha, useTheme } from '@mui/material/styles';
import Container from 'components/Container';
import AppBar from '@mui/material/AppBar';
import { AuthProvider } from 'context/AuthContext';
import WithToggledSidebar from 'components/WithToggledSidebar';
import ContentWrapper from 'components/ContentWrapper';
import Topbar from 'components/Topbar';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import 'react-lazy-load-image-component/src/effects/blur.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import 'react-image-lightbox/style.css';
import 'aos/dist/aos.css';
import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';
import 'process/browser';
import './theme/global.css'

LicenseInfo.setLicenseKey(
    '68769650e9a08ee0392c014567b24c69T1JERVI6MzY2MTUsRVhQSVJZPTE2NzQ3NDk0NjUwMDAsS0VZVkVSU0lPTj0x',
);

const queryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: true
        }
    }
});

const App = (): JSX.Element => {
    const theme = useTheme();

    return (
            <QueryClientProvider client={queryClient}>
                <AuthProvider>
                    <SnackbarProvider
                        maxSnack={3}
                        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                    >
                        <Page>
                            <BrowserRouter>
                                <AppBar
                                    position={'fixed'}
                                    sx={{
                                        backgroundColor:
                                            theme.palette.background.paper,
                                        borderBottom: `1px solid ${alpha(
                                            theme.palette.divider,
                                            0.1,
                                        )}`,
                                    }}
                                    elevation={0}
                                >
                                    <Container
                                        maxWidth={1}
                                        paddingY={{ xs: 1, sm: 1.5 }}
                                    >
                                        <Topbar />
                                    </Container>
                                </AppBar>
                                <WithToggledSidebar>
                                    <ContentWrapper></ContentWrapper>
                                </WithToggledSidebar>
                            </BrowserRouter>
                        </Page>
                    </SnackbarProvider>
                </AuthProvider>
                <ReactQueryDevtools initialIsOpen={false} />
            </QueryClientProvider>
    );
};

export default App;
