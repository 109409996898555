import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../context/AuthContext';

// ADMIN => []
// 
// eslint-disable-next-line react/prop-types
const Private = ({ children }) => {
    const { currentUser } = useAuth();
    return currentUser ? children : <Navigate to="/signin" />;
};

export default Private;
