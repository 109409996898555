import React, { useState } from 'react';
import Box from '@mui/material/Box';
import Sidebar from './Sidebar';
import { useAuth } from 'context/AuthContext';
import { ErrorBoundary } from 'react-error-boundary';
import DashboardManager from 'Manager';

interface Props {
    children: React.ReactNode;
}

const logError = async (error: Error, info: { componentStack: string }, currentUser) => {
    await DashboardManager.logError({info: JSON.stringify(info), userId: JSON.stringify(currentUser?.uid || ''), email: JSON.stringify(currentUser?.email || '')});
};
  
const WithToggledSidebar = ({ children }: Props): JSX.Element => {
    const [openSidebar, setOpenSidebar] = useState(true);
    const { currentUser } = useAuth();

    return (
        <ErrorBoundary fallback={<></>} onError={async (error, info) => logError(error, info, currentUser)}>
            <Box>
                {currentUser && (
                    <Sidebar
                        onClose={() =>
                            setOpenSidebar((openSidebar) => !openSidebar)
                        }
                        open={openSidebar}
                        variant={'permanent'}
                    />
                )}

                <main>
                    <Box height={{ xs: 58, sm: 66, md: 71 }} />
                    <Box
                        display="flex"
                        flex="1 1 auto"
                        overflow="hidden"
                        paddingLeft={{ md: currentUser ? '256px' : '0px' }}
                    >
                        <Box display="flex" flex="1 1 auto" overflow="hidden">
                            <Box display="flex" flex="1 1 auto" overflow="hidden">
                                <Box flex="1 1 auto" height="100%" overflow="auto">
                                    {children}
                                </Box>
                            </Box>
                        </Box>
                    </Box>
                </main>
            </Box>
        </ErrorBoundary>

    );
};

export default WithToggledSidebar;
