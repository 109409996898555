/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Box from '@mui/material/Box';
import {
    Alert,
    Autocomplete,
    Grid,
    Link,
    TextField,
    Typography,
    AlertTitle
} from '@mui/material';
import Container from 'components/Container';
import { Link as NavLink, useNavigate } from 'react-router-dom';
import { useAuth } from 'context/AuthContext';
import DashboardManager from 'Manager';
import Distributor from 'types/Distributor';
import { doc, setDoc, runTransaction } from 'firebase/firestore';
import { firestore } from '../../firebaseSetUp/firebaseSetUp';
import { AccountType } from 'types/User';
import { useMutation, useQuery } from 'react-query';
import LoadingButton from '@mui/lab/LoadingButton';

const SignUpFormWithCoverImage = (): JSX.Element => {
    const { signup } = useAuth();
    const navigate = useNavigate();
    const [error, setError] = React.useState<string>('');
    
    const {
        isLoading,
        data : distributors,
    } = useQuery(
        'distributors',
        async () => {
            return await DashboardManager.getDistributors();
        },
    );

    const [accountType, setAccountType] = React.useState<AccountType | null>(
        AccountType.FABRICATOR,
    );

    const [value, setValue] = React.useState<Distributor | null>(null);

    const { mutate, isLoading: isMutatingLoading, isError } = useMutation(
        async ({
            email,
            password, 
            companyName,
            address: {
                streetName,
                city,
                state,
                zipCode,
            },
            phoneNumber,
            parentId,
            accountType
        }: any) => {
            try {
                await runTransaction(firestore, async (transaction) => {
                    const {
                        user: { uid },
                    } = await signup(email, password);
                    
                    await setDoc(doc(firestore, 'users', uid), {
                        id: uid,
                        name: companyName,
                        address: {
                            streetName,
                            city,
                            state,
                            zipCode,
                        },
                        email,
                        accountType: accountType,
                        phoneNumber,
                        parentId
                    });
                });
                navigate('/');
            } catch (e) {
                setError(`${e.message}`);
                setTimeout(() => setError(''), 3000);
            }
        }
    );

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        const email: string | undefined = data.get('email')?.toString();
        const password: string | undefined = data.get('password')?.toString();
        const companyName: string | undefined = data
            .get('companyName')
            ?.toString();

        const streetName: string | undefined = data
            .get('streetName')
            ?.toString();

        const city: string | undefined = data.get('city')?.toString();

        const state: string | undefined = data.get('state')?.toString();

        const zipCode: string | undefined = data.get('zipCode')?.toString();

        const phoneNumber: string | undefined = data
            .get('phoneNumber')
            ?.toString();

        if (!validateForm(data)) {
            return;
        }

        const userData = {
            email,
            password, 
            companyName,
            address: {
                streetName,
                city,
                state,
                zipCode,
            },
            phoneNumber,
            parentId: value?.id || '0',
            accountType
        };

        mutate(userData, {onError(error, variables, context) {
            console.log(error);
        },});
    };

    const validateForm = (data: FormData) => {
        let isValid = true;

        const fieldToValidate = [
            'companyName',
            'email',
            'password',
            'streetName',
            'city',
            'state',
            'zipCode',
            'phoneNumber',
        ];

        for (let index = 0; index < fieldToValidate.length; index++) {
            const value = data.get(fieldToValidate[index]);
            if (!value || !accountType) {
                isValid = false;
                setError(`Invalid ${fieldToValidate[index]}`);
                setTimeout(() => setError(''), 3000);
                return false;
            }
        }

        return isValid;
    };

    if (isLoading) return <>Loading...</>
    
    if (isError) return <>Error...</>

    return (
        <Box
            sx={{
                width: 1,
                height: 1,
                overflow: 'hidden',
            }}
        >
            {
                isError && 
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    Error Creating Account. Please Try A Different Email!
                </Alert>
            }
            <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
                <Box
                    display={'flex'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    position={'relative'}
                >
                    <Box
                        width={1}
                        order={{ xs: 2, md: 1 }}
                        display={'flex'}
                        alignItems={'center'}
                        marginRight={{ xs: 0, md: 4 }}
                    >
                        <Container>
                            <Box>
                                <Box marginBottom={4}>
                                    <Typography
                                        sx={{
                                            textTransform: 'uppercase',
                                        }}
                                        gutterBottom
                                        color={'text.secondary'}
                                        fontWeight={700}
                                    >
                                        Signup
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: 700,
                                        }}
                                    >
                                        Create an account
                                    </Typography>
                                </Box>
                                {error && (
                                    <Alert severity="error" sx={{ mb: 2 }}>
                                        {error}
                                    </Alert>
                                )}
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Company Name *"
                                                variant="outlined"
                                                name={'companyName'}
                                                fullWidth
                                                autoComplete="true"
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Email *"
                                                variant="outlined"
                                                name={'email'}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <TextField
                                                label="Password *"
                                                variant="outlined"
                                                name={'password'}
                                                type={'password'}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Grid
                                                container
                                                direction="row"
                                                justifyContent="space-between"
                                                alignItems="center"
                                            >
                                                <Grid>
                                                    <TextField
                                                        label="Street Name *"
                                                        variant="outlined"
                                                        name={'streetName'}
                                                        type={'streetName'}
                                                    />
                                                </Grid>
                                                <Grid>
                                                    <TextField
                                                        label="City *"
                                                        variant="outlined"
                                                        name={'city'}
                                                        type={'city'}
                                                    />
                                                </Grid>
                                                <Grid>
                                                    <TextField
                                                        label="State *"
                                                        variant="outlined"
                                                        name={'state'}
                                                        type={'state'}
                                                    />
                                                </Grid>
                                                <Grid>
                                                    <TextField
                                                        label="Zipcode *"
                                                        variant="outlined"
                                                        name={'zipCode'}
                                                        type={'zipCode'}
                                                    />
                                                </Grid>
                                            </Grid>
                                        </Grid>

                                        <Grid item xs={12}>
                                            <TextField
                                                label="Phone Number *"
                                                variant="outlined"
                                                name={'phoneNumber'}
                                                type={'phoneNumber'}
                                                fullWidth
                                            />
                                        </Grid>

                                        <Grid item xs={12}>
                                            <Autocomplete
                                                fullWidth
                                                value={accountType}
                                                onChange={(
                                                    event: any,
                                                    newValue: AccountType | null,
                                                ) => {
                                                    setAccountType(newValue);
                                                }}
                                                id="controllable-states-demo"
                                                options={[AccountType.FABRICATOR, AccountType.DISTRIBUTOR]}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Account Type"
                                                    />
                                                )}
                                            />
                                        </Grid>

                                        {distributors && distributors.length > 0 &&
                                            accountType !==
                                                AccountType.DISTRIBUTOR && (
                                                <Grid item xs={12}>
                                                    <Typography
                                                        variant={'subtitle2'}
                                                        sx={{ marginBottom: 2 }}
                                                    >
                                                        Select Your Distributor
                                                    </Typography>
                                                    <Autocomplete
                                                        value={value}
                                                        onChange={(
                                                            event: any,
                                                            newValue: Distributor | null,
                                                        ) => {
                                                            setValue(newValue);
                                                        }}
                                                        id="distributor"
                                                        options={distributors}
                                                        renderInput={(
                                                            params,
                                                        ) => (
                                                            <TextField
                                                                {...params}
                                                            />
                                                        )}
                                                        getOptionLabel={(
                                                            option,
                                                        ) => {
                                                            if (
                                                                typeof option ===
                                                                'string'
                                                            ) {
                                                                return option;
                                                            }
                                                            return `${option.name} - ${option.address.streetName} ${option.address.city} ${option.address.state} ${option.address.zipCode}`;
                                                        }}
                                                    />
                                                </Grid>
                                            )}
                                        <Grid item container xs={12}>
                                            <Box
                                                display="flex"
                                                flexDirection={{
                                                    xs: 'column',
                                                    sm: 'row',
                                                }}
                                                alignItems={{
                                                    xs: 'stretched',
                                                    sm: 'center',
                                                }}
                                                justifyContent={'space-between'}
                                                width={1}
                                                margin={'0 auto'}
                                            >
                                                <Box
                                                    marginBottom={{
                                                        xs: 1,
                                                        sm: 0,
                                                    }}
                                                >
                                                    <Typography
                                                        variant={'subtitle2'}
                                                    >
                                                        Already have an account?{' '}
                                                        <NavLink
                                                            to="/signin"
                                                            style={{
                                                                textDecoration:
                                                                    'none',
                                                            }}
                                                        >
                                                            <Link
                                                                component={'a'}
                                                                color={
                                                                    'primary'
                                                                }
                                                                underline={
                                                                    'none'
                                                                }
                                                            >
                                                                Login.
                                                            </Link>
                                                        </NavLink>
                                                    </Typography>
                                                </Box>
                                                <LoadingButton
                                                    size={'large'}
                                                    variant={'contained'}
                                                    type={'submit'}
                                                    loading={isMutatingLoading}
                                                >
                                                    Sign up
                                                </LoadingButton>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Container>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default SignUpFormWithCoverImage;
