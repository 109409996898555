import { Box, Link, Typography } from '@mui/material';
import React from 'react';

const DefaultPage = () => {
    return (
        <Box sx={{
            height: '80vh',
            width: '90%',
            m: 'auto',
        }}>
            <Typography variant="h2" gutterBottom>
                Welcome to Expo Window Fashion Inc.
            </Typography>

            <Typography variant="body2">
                This application is created to help you calculate the dimensions of our product and the raw material needed to order from your distributors. Please reach out to <Link>hien.qqnguyen@gmail.com</Link> if you have any questions or concerns.
            </Typography>
        </Box>
    );
};

export default DefaultPage;
