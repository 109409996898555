import React from 'react';
import {
    Alert,
    AlertTitle,
    Box,
    Button,
    ButtonGroup,
    useTheme,
} from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Window from 'types/Window';
import Avatar from '@mui/material/Avatar';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemAvatar from '@mui/material/ListItemAvatar';
import ListItemText from '@mui/material/ListItemText';
import WindowIcon from '@mui/icons-material/Window';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import EditIcon from '@mui/icons-material/Edit';
import WindowModalComponent from './WindowModalComponent';

interface WindowsContainerComponentProps {
    windows: Window[];
    addWindow: (window: Window) => void;
    deleteWindow: (id) => void;
    editWindow: (window: Window) => void;
}

const WindowsContainerComponent: React.FC<WindowsContainerComponentProps> = ({
    windows,
    addWindow,
    deleteWindow,
    editWindow,
}) => {
    const theme = useTheme();

    const [selectedWindow, setSelectedWindow] =
        React.useState<Window>(undefined);

    const [isWindowModalOpen, setIsWindowModalOpen] = React.useState(false);
    const handleOpenWindowModal = () => setIsWindowModalOpen(true);
    const handleCloseWindowModal = () => setIsWindowModalOpen(false);

    const handleEditWindow = (windowId: string) => {
        setSelectedWindow(
            windows.filter(({ id }) => id === windowId)[0] || null,
        );
        handleOpenWindowModal();
    };

    const handleAddWindow = () => {
        setSelectedWindow(undefined);
        handleOpenWindowModal();
    };

    return (
        <Box sx={{ m: '10px' }}>
            <Box sx={{ mb: '20px' }}>
                <ButtonGroup variant="contained" aria-label="text button group">
                    <Button
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={handleAddWindow}
                    >
                        Add Window
                    </Button>
                </ButtonGroup>
            </Box>
            <Box>
                {windows.length === 0 ? (
                    <Alert severity="info">
                        <AlertTitle>Empty Windows</AlertTitle>
                        Please <strong> Add Window </strong>
                    </Alert>
                ) : (
                    <List dense={false}>
                        {windows.map((window, id) => {
                            return (
                                <ListItem
                                    key={`window${id}`}
                                    sx={{
                                        backgroundColor:
                                            theme.palette.background.level2,
                                        mb: '5px',
                                    }}
                                    secondaryAction={
                                        <>
                                            <Button
                                                sx={{ mr: '5px' }}
                                                variant="outlined"
                                                startIcon={<EditIcon />}
                                                onClick={() =>
                                                    handleEditWindow(window.id)
                                                }
                                            >
                                                Edit
                                            </Button>
                                            <Button
                                                variant="outlined"
                                                startIcon={
                                                    <DeleteOutlinedIcon />
                                                }
                                                onClick={() =>
                                                    deleteWindow(window.id)
                                                }
                                            >
                                                Delete
                                            </Button>
                                        </>
                                    }
                                >
                                    <ListItemAvatar>
                                        <Avatar>
                                            <WindowIcon />
                                        </Avatar>
                                    </ListItemAvatar>
                                    <ListItemText
                                        primary={`${window.frameKind.name} - ${window.frameDesign.name}`}
                                        secondary={`#Panels: ${window.numberOfPanels.id} - ${window.panelDesign.name}. Width: ${window.width} in. Height: ${window.height} in`}
                                    />
                                </ListItem>
                            );
                        })}
                    </List>
                )}
            </Box>
            <WindowModalComponent
                window={selectedWindow}
                isWindowModalOpen={isWindowModalOpen}
                handleCloseWindowModal={handleCloseWindowModal}
                addWindow={addWindow}
                editWindow={editWindow}
            ></WindowModalComponent>
        </Box>
    );
};

export default WindowsContainerComponent;
