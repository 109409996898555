import React, { useState } from 'react';
import { useTheme } from '@mui/material/styles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import EditIcon from '@mui/icons-material/Edit';
import Container from 'components/Container';
import {
    Box,
    Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import Modal from '@mui/material/Modal';
import Account from './AccountComponent';
import DashboardManager from 'Manager';
import { useAuth } from 'context/AuthContext';
import { useQuery } from 'react-query';
import { AccountType } from 'types/User';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '80%',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    overflowY: 'auto',
};

type AccountsProps = {
    accountType: AccountType
};

const Accounts : React.FC<AccountsProps>  = ({accountType})=> {

    const { currentUser } = useAuth();

    const {
        isLoading,
        isError,
        data: accounts,
    } = useQuery(
        accountType,
        async () => {
            return await DashboardManager.getAccounts(currentUser.uid, accountType);
        },
    );

    const theme = useTheme();

    const [selectedAccount, setSelectedAccount] = useState<any>(null);

    const [open, setOpen] = React.useState(false);

    const [isEditing, setIsEditing] = React.useState(false);

    const handleOpen = () => setOpen(true);

    const handleClose = () => {
        setOpen(false);
        setIsEditing(false);
        setSelectedAccount(null);
    };

    const handleEdit = (item) => {
        setSelectedAccount(item);
        setIsEditing(true);
        setOpen(true);
    };

    if (isLoading) {
        return <>Loading...</>
    }

    if (isError) {
        return <>Cannot fetch dealers...</>
    }

    return (
        <Container>
            <div>
                <Button
                    variant="contained"
                    startIcon={<AddIcon />}
                    sx={{ mb: '5px' }}
                    onClick={handleOpen}
                >
                    Add {accountType}
                </Button>
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Account
                            handleClose={handleClose}
                            account={selectedAccount}
                            isEditing={isEditing}
                            accountType={accountType}
                        ></Account>
                    </Box>
                </Modal>
            </div>
            <TableContainer component={Paper}>
                <Table sx={{ minWidth: 750 }} aria-label="simple table">
                    <TableHead sx={{ bgcolor: 'alternate.dark' }}>
                        <TableRow>
                            <TableCell>
                                <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'uppercase' }}
                                >
                                    Name
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'uppercase' }}
                                >
                                    Email
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'uppercase' }}
                                >
                                    Address
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'uppercase' }}
                                >
                                    Phone Number
                                </Typography>
                            </TableCell>
                            <TableCell>
                                <Typography
                                    variant={'caption'}
                                    fontWeight={700}
                                    sx={{ textTransform: 'uppercase' }}
                                >
                                    Status
                                </Typography>
                            </TableCell>
                            <TableCell />
                            <TableCell />
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {accounts.map((item, i) => {
                            return (
                                <TableRow
                                    key={i}
                                    sx={{
                                        '&:last-child td, &:last-child th': {
                                            border: 0,
                                        },
                                    }}
                                >
                                    <TableCell component="th" scope="row">
                                        <Typography
                                            variant={'subtitle2'}
                                            fontWeight={700}
                                        >
                                            {item.name}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            color={'text.secondary'}
                                            variant={'subtitle2'}
                                        >
                                            {item.email}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            color={'text.secondary'}
                                            variant={'subtitle2'}
                                        >
                                            {`${item.address.streetName}, ${item.address.city}, ${item.address.state} ${item.address.zipCode}`}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            color={'text.secondary'}
                                            variant={'subtitle2'}
                                        >
                                            {item.phoneNumber}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Typography
                                            variant={'caption'}
                                            fontWeight={700}
                                            sx={{
                                                color: theme.palette.success
                                                    .main,
                                            }}
                                        >
                                            {item.active
                                                ? 'Active'
                                                : 'Disabled'}
                                        </Typography>
                                    </TableCell>
                                    <TableCell>
                                        <Button
                                            variant="outlined"
                                            startIcon={<EditIcon />}
                                            onClick={() => handleEdit(item)}
                                        >
                                            Edit
                                        </Button>
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
};

export default Accounts;
