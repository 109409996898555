import * as math from 'mathjs';
import Window from 'types/Window';

export default class WindowDataProcessingUtils {
    static possibleFractionValues = [
        1 / 32,
        2 / 32,
        3 / 32,
        4 / 32,
        5 / 32,
        6 / 32,
        7 / 32,
        8 / 32,
        9 / 32,
        10 / 32,
        11 / 32,
        12 / 32,
        13 / 32,
        13 / 32,
        14 / 32,
        15 / 32,
        16 / 32,
        17 / 32,
        18 / 32,
        19 / 32,
        20 / 32,
        21 / 32,
        22 / 32,
        23 / 32,
        24 / 32,
        25 / 32,
        26 / 32,
        27 / 32,
        28 / 32,
        29 / 32,
        30 / 32,
        31 / 32,
    ];

    static findClosestToPossibleFractionValue = (n: number): number => {
        let closestNumber = Infinity;
        let result = 0;
        for (const num of this.possibleFractionValues) {
            if (Math.abs(num - n) < closestNumber) {
                closestNumber = Math.abs(num - n);
                result = num;
            }
        }
        return result;
    };

    static computeWidthCut = (width_measure: number, delta: number): number => {
        return width_measure + delta;
    };

    static computeWidthFit = (width_measure: number, delta: number): number => {
        return width_measure + delta;
    };

    static computeWidthInFrame = (
        width_measure: number,
        delta: number,
    ): number => {
        return width_measure + delta;
    };

    static computeHeightCut = (
        height_measure: number,
        delta: number,
    ): number => {
        return height_measure + delta;
    };

    static computeHeightFit = (
        height_measure: number,
        delta: number,
    ): number => {
        return height_measure + delta;
    };

    static computeHeightInFrame = (
        height_measure: number,
        delta: number,
    ): number => {
        return height_measure + delta;
    };

    static computePanelWidth = (
        width_measure: number,
        number_of_panels: number,
        delta: number,
        panelDesignDelta: number,
    ): number => {
        return (
            (this.computeWidthInFrame(width_measure, delta) -
                panelDesignDelta) /
            number_of_panels
        );
    };

    static computeTopAndBottomRailWidth = (
        width_measure: number,
        number_of_panels: number,
        delta: number,
        panelDesignDelta: number,
    ): number => {
        return (
            this.computePanelWidth(
                width_measure,
                number_of_panels,
                delta,
                panelDesignDelta,
            ) - 3.5
        );
    };

    static computeLouverWidth = (
        width_measure: number,
        number_of_panels: number,
        delta: number,
        panelDesignDelta: number,
    ): number => {
        return (
            this.computePanelWidth(
                width_measure,
                number_of_panels,
                delta,
                panelDesignDelta,
            ) - 3.625
        );
    };

    static computeSQ = (
        width_measure: number,
        height_measure: number,
    ): number => {
        return this.roundUp((width_measure * height_measure) / 144, 2);
    };

    static computePanelHeight = (
        height_measure: number,
        delta: number,
    ): number => {
        return this.computeHeightInFrame(height_measure, delta) - 7 / 16;
    };

    static computeTPodMaterial = (
        height_measure: number,
        delta: number,
        number_of_t_pod: number,
    ): number => {
        return this.roundUp(
            (this.computeHeightInFrame(height_measure, delta) *
                number_of_t_pod) /
                12,
            1,
        );
    };

    static computeNumberOfLouver = (
        height_measure: number,
        louver_size: number,
        number_of_panels: number,
        delta: number,
        divRail: number | undefined,
        bottomLouvers: number | undefined,
        topLouvers: number | undefined,
    ): number => {
        if (topLouvers || bottomLouvers) {
            return (topLouvers + bottomLouvers) * number_of_panels;
        }

        let extra: number;
        if (divRail && divRail > 0) {
            extra = 1;
        } else {
            extra = 0;
        }

        return Math.floor(
            (this.computePanelHeight(height_measure, delta) / louver_size -
                2.5 -
                extra) *
                number_of_panels,
        );
    };

    static computeTotalMaterial = (windows: Array<Window>) => {
        let totalLouverMaterial = 0;
        let totalTopRailWidthMaterial = 0;
        let totalBottomRailWidthMaterial = 0;
        let totalMagicTiltMaterial = 0;
        let totalStileMaterial = 0;
        let totalCutAluminumMaterial = 0;
        let totalSQMaterial = 0;
        let totalTPodMaterial = 0;
        let totalFiveInchMaterial = 0;
        let totalThreeInchMaterial = 0;

        const frameMaterialType = { Z: 0, S: 0, OB: 0 };

        windows?.forEach((window) => {
            totalLouverMaterial += window.louverMaterial;
            totalTopRailWidthMaterial += window.topRailWidthMaterial;
            totalBottomRailWidthMaterial += window.bottomRailWidthMaterial;
            totalMagicTiltMaterial += window.magicTiltMaterial;
            totalStileMaterial += window.stileMaterial;
            frameMaterialType.Z += window.frameMaterialType.Z;
            frameMaterialType.S += window.frameMaterialType.S;
            frameMaterialType.OB += window.frameMaterialType.OB;
            totalCutAluminumMaterial += window.cutAluminumMaterial;
            totalSQMaterial += window.sqMaterial;
            totalTPodMaterial += window.tPodMaterial;
            totalFiveInchMaterial += window.fiveInchMaterial;
            totalThreeInchMaterial += window.threeInchMaterial;
        });

        Object.keys(frameMaterialType).forEach(
            (key) =>
                (frameMaterialType[key] = WindowDataProcessingUtils.roundUp(
                    frameMaterialType[key],
                    2,
                )),
        );

        return {
            totalTPodMaterial: WindowDataProcessingUtils.roundUp(
                totalTPodMaterial,
                1,
            ),
            totalSQMaterial: WindowDataProcessingUtils.roundUp(
                totalSQMaterial,
                1,
            ),
            totalLouverMaterial: WindowDataProcessingUtils.roundUp(
                totalLouverMaterial,
                1,
            ),
            totalTopRailWidthMaterial: WindowDataProcessingUtils.roundUp(
                totalTopRailWidthMaterial,
                1,
            ),
            totalBottomRailWidthMaterial: WindowDataProcessingUtils.roundUp(
                totalBottomRailWidthMaterial,
                1,
            ),
            totalStileMaterial: WindowDataProcessingUtils.roundUp(
                totalStileMaterial,
                1,
            ),
            totalCutAluminumMaterial: WindowDataProcessingUtils.roundUp(
                totalCutAluminumMaterial,
                1,
            ),
            totalMagicTiltMaterial: WindowDataProcessingUtils.roundUp(
                totalMagicTiltMaterial,
                1,
            ),
            totalFiveInchMaterial: WindowDataProcessingUtils.roundUp(
                totalFiveInchMaterial,
                1,
            ),
            totalThreeInchMaterial: WindowDataProcessingUtils.roundUp(
                totalThreeInchMaterial,
                1,
            ),
            frameMaterialType,
            ...frameMaterialType
        };
    };

    static computeStileMaterial = (
        height_measure: number,
        number_of_panels: number,
        delta: number,
    ) => {
        return (
            2 *
            number_of_panels *
            this.computePanelHeight(height_measure, delta)
        );
    };

    static computeTopAndBottomRailWidthMaterial = (
        width_measure: number,
        number_of_panels: number,
        delta: number,
        panelDesignDelta: number,
    ) => {
        return (
            number_of_panels *
            this.roundUp(
                this.computeTopAndBottomRailWidth(
                    width_measure,
                    number_of_panels,
                    delta,
                    panelDesignDelta,
                ) / 12,
                2,
            )
        );
    };

    static computeLouverMaterial = (
        number_of_louvers: number,
        louver_width: number,
    ) => {
        return this.roundUp((number_of_louvers * louver_width) / 12, 2);
    };

    static computeDividerRailMaterial = (
        number_of_panels: number,
        top_and_bottom_rail_width: number,
    ) => {
        return this.roundUp(
            (number_of_panels * top_and_bottom_rail_width) / 12,
            2,
        );
    };

    static computeMagicTiltMaterial = (
        number_of_panels: number,
        panel_height: number,
    ) => {
        return this.roundUp((number_of_panels * panel_height) / 12, 2);
    };

    static computeCutAluminum = (
        number_of_panels: number,
        panel_height: number,
    ) => {
        return this.roundUp((2 * (number_of_panels * panel_height)) / 12, 2);
    };

    static computeTopAndBottomRailWithHeightAndDivRail = (
        height: number,
        divRail: number | undefined,
        deltaObject
    ) => {
        const panelHeight = this.computePanelHeight(height, deltaObject.heightDeltaInFrame);
        if (!divRail || divRail === 0) {
            const numberOfLouver = Math.floor(
                panelHeight / 6 - 1,
            );

            const railHeight =
                (panelHeight - 2 * numberOfLouver * 3) /
                2;

            let adjustedBottomRailHeight = railHeight;
            let adjustedTopRailHeight = railHeight;

            if (String(railHeight).slice(-4) === '3125') {
                adjustedBottomRailHeight = railHeight + 1 / 32;
                adjustedTopRailHeight = railHeight - 1 / 32;
            }

            return {
                bottomRailHeight: adjustedBottomRailHeight,
                topRailHeight: adjustedTopRailHeight,
                bottomLouvers: 2 * numberOfLouver,
                topLouvers: 0,
            };
        } else {
            let min = Infinity;
            let result: any = [];
            const maxBottomLouvers = Math.round(divRail / 3) + 1;
            const maxTopLouvers = Math.round(panelHeight / 3) + 1;
            for (
                let bottomLouvers = maxBottomLouvers;
                bottomLouvers > 0;
                bottomLouvers--
            ) {
                const bottomRailHeight = divRail - bottomLouvers * 3 - 1.5 - 1;
                let topRailHeight = 0;
                for (
                    let topLouvers = maxTopLouvers;
                    topLouvers > 0;
                    topLouvers--
                ) {
                    topRailHeight =
                        panelHeight -
                        bottomRailHeight -
                        topLouvers * 3 -
                        bottomLouvers * 3 -
                        3;
                    if (
                        topRailHeight >= 2.5 &&
                        topRailHeight <= 5.625 &&
                        bottomRailHeight >= 2.5 &&
                        bottomRailHeight <= 5.625
                    ) {
                        if (Math.abs(topRailHeight - bottomRailHeight) < min) {
                            min = Math.abs(topRailHeight - bottomRailHeight);
                            result = {
                                bottomRailHeight: bottomRailHeight,
                                topRailHeight: topRailHeight,
                                bottomLouvers,
                                topLouvers,
                            };
                        }
                    }
                }
            }

            return result;
        }
    };

    static computeTopAndBottomRailWithBottomLouvers = (
        height: number,
        divRail: number | undefined,
        deltaObject,
        bottomLouvers: number,
        row: any,
    ) => {
        const panelHeight = this.computePanelHeight(height, deltaObject.heightDeltaInFrame);

        if (!divRail || divRail === 0) {

            const railHeight =
                (panelHeight - bottomLouvers * 3) /
                2;

            let adjustedBottomRailHeight = railHeight;
            let adjustedTopRailHeight = railHeight;

            if (String(railHeight).slice(-4) === '3125') {
                adjustedBottomRailHeight = railHeight + 1 / 32;
                adjustedTopRailHeight = railHeight - 1 / 32;
            }

            return {
                bottomRailHeight: adjustedBottomRailHeight,
                topRailHeight: adjustedTopRailHeight,
                bottomLouvers: bottomLouvers,
                topLouvers: 0,
            };
        } else {
            return {
                bottomRailHeight: divRail - 3 * bottomLouvers - 1.5 - 1,
                topRailHeight: row.topRailHeight,
                bottomLouvers,
                topLouvers: row.topLouvers,
            };
        }
    };

    static computeTopAndBottomRailWithBottomRailHeight = (
        height: number,
        divRail: number | undefined,
        deltaObject,
        bottomRailHeight: number,
        panelNumber: number,
        row: any,
    ) => {
        const isUsingDivRail = divRail && divRail !== 0; 
        const panelHeight = this.computePanelHeight(height, deltaObject.heightDeltaInFrame);

        let topRailHeight =
            panelHeight -
            bottomRailHeight -
            row.topLouvers * 3 -
            row.bottomLouvers * 3;

        if (isUsingDivRail) topRailHeight -= 3;

        return {
            bottomRailHeight: bottomRailHeight,
            topRailHeight: topRailHeight,
            bottomLouvers: row.bottomLouvers,
            topLouvers: row.topLouvers,
        }
    };

    static computeTopAndBottomRailWithTopRailHeight = (
        height: number,
        divRail: number | undefined,
        deltaObject,
        topRailHeight: number,
        row: any,
    ) => {
        const isUsingDivRail = divRail && divRail !== 0; 
        const panelHeight = this.computePanelHeight(height, deltaObject.heightDeltaInFrame);

        let bottomRailHeight =
            panelHeight -
            topRailHeight -
            row.topLouvers * 3 -
            row.bottomLouvers * 3;

        if (isUsingDivRail) bottomRailHeight -= 3;
                
        return {
            bottomRailHeight: bottomRailHeight,
            topRailHeight: topRailHeight,
            bottomLouvers: row.bottomLouvers,
            topLouvers: row.topLouvers,
        }
    };

    static computeFrameMaterialTypes = (frameDesign, widthCut, heightCut) => {
        const frameMaterialType = { Z: 0, S: 0, OB: 0 };

        if (!frameDesign) return frameMaterialType;

        const topType = frameDesign.name
            ?.split(';')
            .find((s) => s.includes('TOP'))
            ?.split(':')[1]
            .trim();

        const bottomType = frameDesign.name
            ?.split(';')
            .find((s) => s.includes('BOTTOM'))
            ?.split(':')[1]
            .trim();

        const leftType = frameDesign.name
            ?.split(';')
            .find((s) => s.includes('LEFT'))
            ?.split(':')[1]
            .trim();

        const rightType = frameDesign.name
            ?.split(';')
            .find((s) => s.includes('RIGHT'))
            ?.split(':')[1]
            .trim();

        if (topType)
            frameMaterialType[topType] +=
                this.roundUp(
                    Number(this.convertingFractionToNumber(String(widthCut))),
                    2,
                ) || 0;
        if (bottomType)
            frameMaterialType[bottomType] +=
                this.roundUp(
                    Number(this.convertingFractionToNumber(String(widthCut))),
                    2,
                ) || 0;
        if (leftType)
            frameMaterialType[leftType] +=
                this.roundUp(
                    Number(this.convertingFractionToNumber(String(heightCut))),
                    2,
                ) || 0;
        if (rightType)
            frameMaterialType[rightType] +=
                this.roundUp(
                    Number(this.convertingFractionToNumber(String(heightCut))),
                    2,
                ) || 0;

        Object.keys(frameMaterialType).forEach((key) => {
            frameMaterialType[key] = this.roundUp(
                frameMaterialType[key] / 12,
                2,
            );
        });

        return frameMaterialType;
    };

    static computeDerivedValues = (
        width,
        widthCut,
        widthDeltaInFrame,
        heightDeltaInFrame,
        height,
        heightCut,

        frameDesign,

        panelHeight,
        numberOfPanels,
        panelDesign,

        louvers,
        louverWidth,

        topAndBottomRailWidth,
    ) => {
        const frameMaterialType = this.computeFrameMaterialTypes(
            frameDesign,
            widthCut,
            heightCut,
        );

        const tPodCount = (panelDesign.name.match(/T/g) || []).length;

        const tPodMaterial =
            WindowDataProcessingUtils.computeTPodMaterial(
                height,
                heightDeltaInFrame,
                tPodCount,
            ) || 0;

        return {
            tPodMaterial,
            frameMaterialType,
            stileMaterial:
                this.computeCutAluminum(
                    Number((numberOfPanels && numberOfPanels.id) || 0),
                    Number(
                        this.convertingFractionToNumber(String(panelHeight)),
                    ),
                ) || 0,
            bottomRailWidthMaterial: this.roundUp(
                Number(
                    this.computeTopAndBottomRailWidthMaterial(
                        width,
                        Number(numberOfPanels.name),
                        widthDeltaInFrame,
                        panelDesign.value,
                    ),
                ),
                2,
            ),
            topRailWidthMaterial: this.roundUp(
                Number(
                    this.computeTopAndBottomRailWidthMaterial(
                        width,
                        Number(numberOfPanels.name),
                        widthDeltaInFrame,
                        panelDesign.value,
                    ),
                ),
                2,
            ),
            dividerRailMaterial: this.computeDividerRailMaterial(
                numberOfPanels.id,
                topAndBottomRailWidth,
            ),
            louverMaterial:
                this.computeLouverMaterial(
                    louvers,
                    Number(
                        this.convertingFractionToNumber(String(louverWidth)),
                    ),
                ) || 0,
            magicTiltMaterial:
                this.computeMagicTiltMaterial(
                    Number((numberOfPanels && numberOfPanels.id) || 0),
                    Number(
                        this.convertingFractionToNumber(String(panelHeight)),
                    ),
                ) || 0,
            cutAluminumMaterial:
                this.computeCutAluminum(
                    Number((numberOfPanels && numberOfPanels.id) || 0),
                    Number(
                        this.convertingFractionToNumber(String(panelHeight)),
                    ),
                ) || 0,
            sqMaterial: this.computeSQ(Number(width), Number(height)) || 0,
        };
    };

    static computeTopAndBottomRailWithTopLouvers = (
        height: number,
        divRail: number | undefined,
        deltaObject,
        topLouvers: number,
        row: any,
    ) => {
        console.log(height, divRail, topLouvers);
        const deltaToCalculatePanelHeight = 1.75 + 3 / 16;

        if (!divRail || divRail === 0) {
            const numberOfLouver = Math.floor(
                (height - deltaToCalculatePanelHeight) / 6 - 1,
            );

            const railHeight =
                (height -
                    deltaToCalculatePanelHeight -
                    2 * numberOfLouver * 3) /
                2;

            let adjustedBottomRailHeight = railHeight;
            let adjustedTopRailHeight = railHeight;

            if (String(railHeight).slice(-4) === '3125') {
                adjustedBottomRailHeight = railHeight + 1 / 32;
                adjustedTopRailHeight = railHeight - 1 / 32;
            }

            return {
                bottomRailHeight: adjustedBottomRailHeight,
                topRailHeight: adjustedTopRailHeight,
                bottomLouvers: 2 * numberOfLouver,
                topLouvers: 0,
            };
        } else {
            const panelHeight = height - deltaToCalculatePanelHeight;
            const topRailHeight =
                panelHeight -
                row.bottomRailHeight -
                topLouvers * 3 -
                row.bottomLouvers * 3 -
                3;

            return {
                bottomRailHeight: row.bottomRailHeight,
                topRailHeight: topRailHeight,
                bottomLouvers: row.bottomLouvers,
                topLouvers,
            };
        }
    };

    static validateInput = (email: string): boolean => {
        const re = new RegExp(/(^\d+ \d+\/\d+$)|(^\d+$)|(^\d+\/\d+$)/gm);
        const secondRe = new RegExp(/^\d*(\.\d+)?$/);
        return (
            re.test(String(email).toLowerCase()) ||
            secondRe.test(String(email).toLowerCase())
        );
    };

    static convertingFractionToNumber = (fraction: string): number | string => {
        if (this.validateInput(fraction)) {
            const [partOne, partTwo] = fraction.split(' ');
            return math.evaluate(`${partOne} + ${partTwo ? partTwo : '0'}`);
        } else {
            return fraction;
        }
    };

    static convertingNumberToFraction = (number: number | undefined) => {
        if (!number) return '';

        const [partOne, partTwo] = (number + '').trim().split('.');
        if (partTwo && partTwo.length > 5) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return `${partOne} ${math
                .fraction(
                    this.findClosestToPossibleFractionValue(
                        Number(String('0.' + partTwo)),
                    ).toString(),
                )
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                .toFraction()}`;
        } else if (partTwo && partTwo.length > 0) {
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            return `${partOne} ${math.fraction('0.' + partTwo).toFraction()}`;
        } else {
            return `${partOne}`;
        }
    };

    static roundUp = (num: number, precision: number) => {
        precision = Math.pow(10, precision);
        return Math.ceil(num * precision) / precision;
    };

    static getDeltaBasedOnFrameType(frameKind: string) {
        const extractFrameKind = frameKind
            ? frameKind.trim().split('-')[0]
            : '';
        switch (extractFrameKind.trim()) {
            case 'New Decor Z':
                return {
                    widthDeltaCut: 2.75,
                    widthDeltaFit: -0.25,
                    widthDeltaInFrame: -1.75,
                    heightDeltaCut: 2.75,
                    heightDeltaFit: -0.25,
                    heightDeltaInFrame: -1.75,
                };

            case 'OB Frame': {
                return {
                    widthDeltaCut: 0,
                    widthDeltaFit: 0,
                    widthDeltaInFrame: -3.25,
                    heightDeltaCut: -0.625,
                    heightDeltaFit: -5.375,
                    heightDeltaInFrame: -2.375,
                };
            }

            case 'OB Frame Tip to Tip': {
                return {
                    widthDeltaCut: 0,
                    widthDeltaFit: 0,
                    widthDeltaInFrame: -3.25,
                    heightDeltaCut: -0.625,
                    heightDeltaFit: -5.375,
                    heightDeltaInFrame: -2.375,
                };
            }

            case 'Sill Frame IB': {
                return {
                    widthDeltaCut: -0.25,
                    widthDeltaFit: -0.25,
                    widthDeltaInFrame: -1.75,
                    heightDeltaCut: -0.25,
                    heightDeltaFit: -0.25,
                    heightDeltaInFrame: -1.75,
                };
            }

            case 'Sill Frame OB': {
                return {
                    widthDeltaCut: 0,
                    widthDeltaFit: 0,
                    widthDeltaInFrame: -3.25,
                    heightDeltaCut: -0.625,
                    heightDeltaFit: -5.375,
                    heightDeltaInFrame: -2.375,
                };
            }

            case 'Sill Frame Tip to Tip': {
                return {
                    widthDeltaCut: 0,
                    widthDeltaFit: 0,
                    widthDeltaInFrame: -1.5,
                    heightDeltaCut: 0,
                    heightDeltaFit: 0,
                    heightDeltaInFrame: -1.5,
                };
            }
        }
        return {
            widthDeltaCut: 2.5625,
            widthDeltaFit: -0.25,
            widthDeltaInFrame: -1.75,
            heightDeltaCut: 2.5625,
            heightDeltaFit: -0.25,
            heightDeltaInFrame: -1.75,
        };
    }

    static transformFractionToNumber = (dataRow: Window): Window => {
        Object.keys(dataRow).forEach((key) => {
            if (
                key !== 'frameKind' &&
                key !== 'frameDesign' &&
                key !== 'numberOfPanels' &&
                key !== 'panelDesign' &&
                key !== 'id'
            ) {
                dataRow[key] =
                    WindowDataProcessingUtils.convertingFractionToNumber(
                        String(dataRow[key]),
                    );
            }
        });

        return dataRow;
    };

    static transformNumberToFraction = (dataRow: Window): Window => {
        Object.keys(dataRow).forEach((key) => {
            if (
                key !== 'frameKind' &&
                key !== 'frameDesign' &&
                key !== 'numberOfPanels' &&
                key !== 'panelDesign' &&
                key !== 'id'
            ) {
                dataRow[key] =
                    WindowDataProcessingUtils.convertingNumberToFraction(
                        dataRow[key],
                    );
            }
        });

        return dataRow;
    };
}
