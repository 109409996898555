export enum AccountType {
    FABRICATOR = 'FABRICATOR',
    DISTRIBUTOR = 'DISTRIBUTOR',
    DEALER = 'DEALER',
    ADMIN = 'ADMIN',
}

export default interface User {
    id: string;
    parentId: string,
    email: string,
    accountType: AccountType,
    name?: string,
    address: {
        city?: string;
        state?: string;
        streetName?: string;
        zipCode?: string;
    };
    phoneNumber?: string;
    active?: boolean;
    //temp
    distributorId?: string,
}