import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGridPro,
    GridColDef,
    GridRenderCellParams,
    GridToolbar,
    GridValueGetterParams,
} from '@mui/x-data-grid-pro';
import { renderStatus } from './renderStatus';
import { renderEditStatus } from './renderEditStatus';
import OrderDashboardManager from 'Manager';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Button, LinearProgress, Modal } from '@mui/material';
import Order, { OrderType } from 'types/Order';
import { useQuery } from 'react-query';
import OrderComponent from './DealerOrderComponent';
import WindowDataProcessingUtils from 'pages/utils/WindowDataProcessingUtils';
import WindowUtils from 'pages/utils/WindowUtils';
import { useAuth } from 'context/AuthContext';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

const orderColumns: (
    handleSetOpen: () => void,
    handleSetOrderNumber: (order: Order) => void,
) => GridColDef[] = (handleSetOpen, handleSetOrderNumber) => {
    return [
        {
            field: 'orderNumber',
            headerName: 'Order Number',
            width: 120,
        },
        {
            field: 'status',
            headerName: 'Status',
            width: 130,
            type: 'singleSelect',
            valueOptions: ['Open', 'PartiallyFilled', 'Filled', 'Rejected'],
            renderCell: renderStatus,
            renderEditCell: renderEditStatus,
            editable: true,
        },
        {
            field: 'edit',
            headerName: 'Windows',
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Button
                        sx={{
                            width: '100%',
                        }}
                        onClick={() => {
                            handleSetOrderNumber(params.row);
                            handleSetOpen();
                        }}
                        variant="text"
                        startIcon={
                            <OpenInFullIcon color="primary"></OpenInFullIcon>
                        }
                    >
                        Order Detail
                    </Button>
                );
            },
        },
        {
            field: 'date',
            headerName: 'Created On',
            width: 150,
            type: 'date',
        },
        {
            field: 'orderOwnerName',
            headerName: 'Order Owner Name',
            width: 150,
        },
        {
            field: 'job',
            headerName: 'Job',
            width: 150,
            editable: true,
        },
        {
            field: 'color',
            headerName: 'Color',
            width: 150,
            editable: true,
        },
        {
            field: 'sideMarks',
            headerName: 'Side Marks',
            width: 150,
            editable: true,
        },
        // {
        //     field: 'totalSQMaterial',
        //     headerName: 'SQ',
        //     width: 150,
        //     valueGetter: (params: GridValueGetterParams) => {
        //         return params.row.total.totalSQMaterial || '';
        //     },
        // },
        // {
        //     field: 'totalTopRailWidthMaterial',
        //     headerName: 'Top Rail Width Material',
        //     width: 150,
        //     valueGetter: (params: GridValueGetterParams) => {
        //         return (
        //             WindowDataProcessingUtils.roundUp(
        //                 params.row.total.totalTopRailWidthMaterial,
        //                 2,
        //             ) || ''
        //         );
        //     },
        // },
        // {
        //     field: 'totalBottomRailWidthMaterial',
        //     headerName: 'Bottm Rail Width Material',
        //     width: 150,
        //     valueGetter: (params: GridValueGetterParams) => {
        //         return (
        //             WindowDataProcessingUtils.roundUp(
        //                 params.row.total.totalBottomRailWidthMaterial,
        //                 2,
        //             ) || ''
        //         );
        //     },
        // },
        // {
        //     field: 'totalCutAluminumMaterial',
        //     headerName: 'Cut Aluminum Material',
        //     width: 150,
        //     valueGetter: (params: GridValueGetterParams) => {
        //         return (
        //             WindowDataProcessingUtils.roundUp(
        //                 params.row.total.totalCutAluminumMaterial,
        //                 2,
        //             ) || ''
        //         );
        //     },
        // },
        // {
        //     field: 'totalMagicTiltMaterial',
        //     headerName: 'Magic Tilt Material',
        //     width: 150,
        //     valueGetter: (params: GridValueGetterParams) => {
        //         return (
        //             WindowDataProcessingUtils.roundUp(
        //                 params.row.total.totalMagicTiltMaterial,
        //                 2,
        //             ) || ''
        //         );
        //     },
        // },
        // {
        //     field: 'totalLouverMaterial',
        //     headerName: 'Louver Material',
        //     width: 150,
        //     valueGetter: (params: GridValueGetterParams) => {
        //         return (
        //             WindowDataProcessingUtils.roundUp(
        //                 params.row.total.totalLouverMaterial,
        //                 2,
        //             ) || ''
        //         );
        //     },
        // },
        // {
        //     field: 'totalStileMaterial',
        //     headerName: 'Stile Material',
        //     width: 150,
        //     valueGetter: (params: GridValueGetterParams) => {
        //         return (
        //             WindowDataProcessingUtils.roundUp(
        //                 params.row.total.totalStileMaterial,
        //                 2,
        //             ) || ''
        //         );
        //     },
        // },
        // {
        //     field: 'totalTPodMaterial',
        //     headerName: 'T Pod Material',
        //     width: 150,
        //     valueGetter: (params: GridValueGetterParams) => {
        //         return (
        //             WindowDataProcessingUtils.roundUp(
        //                 params.row.total.totalTPodMaterial,
        //                 2,
        //             ) || ''
        //         );
        //     },
        // },
        // {
        //     field: 'totalFiveInchMaterial',
        //     headerName: '5.5 Inch Width Material',
        //     width: 150,
        //     valueGetter: (params: GridValueGetterParams) => {
        //         return (
        //             WindowDataProcessingUtils.roundUp(
        //                 params.row.total.totalFiveInchMaterial,
        //                 2,
        //             ) || ''
        //         );
        //     },
        // },
        // {
        //     field: 'totalThereeInchMaterial',
        //     headerName: '3.5 Inch Width Material',
        //     width: 150,
        //     valueGetter: (params: GridValueGetterParams) => {
        //         return (
        //             WindowDataProcessingUtils.roundUp(
        //                 params.row.total.totalThreeInchMaterial,
        //                 2,
        //             ) || ''
        //         );
        //     },
        // },
        // {
        //     field: 'totalZMaterial',
        //     headerName: 'Z Material',
        //     width: 150,
        //     valueGetter: (params: GridValueGetterParams) => {
        //         return (
        //             WindowDataProcessingUtils.roundUp(
        //                 params.row.total.frameMaterialType.Z,
        //                 2,
        //             ) || ''
        //         );
        //     },
        // },
        // {
        //     field: 'totalSMaterial',
        //     headerName: 'S Material',
        //     width: 150,
        //     valueGetter: (params: GridValueGetterParams) => {
        //         return (
        //             WindowDataProcessingUtils.roundUp(
        //                 params.row.total.frameMaterialType.S,
        //                 2,
        //             ) || ''
        //         );
        //     },
        // },
        // {
        //     field: 'totalOBMaterial',
        //     headerName: 'OB Material',
        //     width: 150,
        //     valueGetter: (params: GridValueGetterParams) => {
        //         return (
        //             WindowDataProcessingUtils.roundUp(
        //                 params.row.total.frameMaterialType.OB,
        //                 2,
        //             ) || ''
        //         );
        //     },
        // }
    ];
};

const defaultOrder: Order = {
    id: '',
    orderType: OrderType.WINDOW,
    dealerId: '',
    windows: [],
    sideMarks: '',
    job: '',
    color: '',
    date: '',
    measuredBy: '',
    orderNumber: 0,
    orderStatus: null
};

export default function DealerOrdersComponent() {
    
    const { currentUser } = useAuth();

    const transformOrder = (orders) => {
        return orders.map((order) => {
            return {
                ...order,
                total: WindowDataProcessingUtils.computeTotalMaterial(
                    order.windows,
                ),
            };
        });
    };

    const {
        isLoading,
        isError,
        data: orders,
    } = useQuery(
        'orders',
        async () => {
            return await OrderDashboardManager.getOrdersForUser(currentUser.uid, OrderType.WINDOW);
        },
        {
            select: transformOrder,
        },
    );

    const [open, setOpen] = React.useState(false);
    
    const handleClose = () => setOpen(false);

    const [selectedOrder, setSelectedOrder] = React.useState<Order>({...defaultOrder, userId: currentUser.uid});

    const isValidOrder = (order: Order): boolean => {
        return (
            order.windows.filter((window) => WindowUtils.isValidWindow(window))
                .length === order.windows.length
        );
    };

    if (isLoading) {
        return <LinearProgress />;
    }

    if (isError) {
        return <span>Error</span>;
    }

    return (
        <React.Fragment>
            <Box
                sx={{
                    width: '90%',
                    m: 'auto',
                    mb:'10px'
                }}
            >
                <Button
                    variant="contained"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => {
                        setSelectedOrder({...defaultOrder, userId: currentUser.uid});
                        setOpen(true);
                    }}
                >
                    Add New Window Order
                </Button>

                {/* <Button
                    sx={{ml: '10px'}}
                    variant="contained"
                    startIcon={<AddCircleOutlineIcon />}
                    onClick={() => {
                        setSelectedOrder({...defaultOrder, userId: currentUser.uid});
                        setOpen(true);
                    }}
                >
                    Calculate Total Material For Selected Orders
                </Button> */}
            </Box>
            <Box
                sx={{
                    height: '80vh',
                    width: '90%',
                    m: 'auto',
                }}
            >
                <DataGridPro
                    sx={{
                        boxShadow: 2,
                        border: 2,
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        },
                        overflow: 'auto',       
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                // Hide columns status and traderName, the other columns will remain visible
                                // job: false,
                                // color: false,
                                // sideMarks: false,
                            },
                        },
                    }}
                    getRowId={(row) => row.id}
                    columns={orderColumns(
                        () => setOpen(true),
                        (order: Order) => setSelectedOrder(order),
                    )}
                    rows={orders}
                    loading={!orders}
                    rowHeight={38}
                    checkboxSelection
                    disableSelectionOnClick
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: { showQuickFilter: true },
                    }}
                />
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute' as const,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        border: '2px #000',
                        boxShadow: 24,
                        p: 4,
                        width: '80%',
                        height: '90%',
                    }}
                >
                    <OrderComponent
                        defaultOrder={selectedOrder}
                        handleCloseModal={handleClose}
                    ></OrderComponent>
                </Box>
            </Modal>
        </React.Fragment>
    );
}
