/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import { useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Container from 'components/Container';
import { useAuth } from 'context/AuthContext';
import { useNavigate } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { Alert } from '@mui/material';
import { Link as NavLink } from 'react-router-dom';

const SignIn = (): JSX.Element => {
    const { signin } = useAuth();
    const [error, setError] = React.useState<string>('');
    const [loading, setLoading] = React.useState<boolean>(false);
    const navigate = useNavigate();

    const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
        // eslint-disable-next-line no-console
        const email: string | undefined = data.get('email')?.toString();
        const password: string | undefined = data.get('password')?.toString();
        try {
            setError('');
            setLoading(true);
            signin(email, password).then((value) => {
                navigate('/');
            });
        } catch (e) {
            setError('Failed to sign in. Please try again!');
            setLoading(false);
            setTimeout(() => setError(''), 2000);
        }

        setLoading(false);
    };

    return (
        <Box
            sx={{
                width: 1,
                height: 1,
                overflow: 'hidden',
            }}
        >
            <Container paddingX={0} paddingY={0} maxWidth={{ sm: 1, md: 1236 }}>
                <Box
                    display={'flex'}
                    flexDirection={{ xs: 'column', md: 'row' }}
                    position={'relative'}
                >
                    <Box
                        width={1}
                        order={{ xs: 2, md: 1 }}
                        display={'flex'}
                        alignItems={'center'}
                        marginRight={{ xs: 0, md: 4 }}
                    >
                        <Container>
                            <Box>
                                <Box marginBottom={4}>
                                    <Typography
                                        sx={{
                                            textTransform: 'uppercase',
                                        }}
                                        gutterBottom
                                        color={'text.secondary'}
                                        fontWeight={700}
                                    >
                                        Login
                                    </Typography>
                                    <Typography
                                        variant="h4"
                                        sx={{
                                            fontWeight: 700,
                                        }}
                                    >
                                        Welcome back
                                    </Typography>
                                    <Typography color="text.secondary">
                                        Login to manage your account
                                    </Typography>
                                </Box>
                                {error && (
                                    <Alert severity="error" sx={{ mb: 2 }}>
                                        {error}
                                    </Alert>
                                )}
                                <form onSubmit={handleSubmit}>
                                    <Grid container spacing={4}>
                                        <Grid item xs={12}>
                                            <Typography
                                                variant={'subtitle2'}
                                                sx={{ marginBottom: 2 }}
                                            >
                                                Enter your email
                                            </Typography>
                                            <TextField
                                                label="Email *"
                                                variant="outlined"
                                                name={'email'}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Box
                                                display="flex"
                                                flexDirection={{
                                                    xs: 'column',
                                                    sm: 'row',
                                                }}
                                                alignItems={{
                                                    xs: 'stretched',
                                                    sm: 'center',
                                                }}
                                                justifyContent={'space-between'}
                                                width={1}
                                                marginBottom={2}
                                            >
                                                <Box
                                                    marginBottom={{
                                                        xs: 1,
                                                        sm: 0,
                                                    }}
                                                >
                                                    <Typography
                                                        variant={'subtitle2'}
                                                    >
                                                        Enter your password
                                                    </Typography>
                                                </Box>
                                                <Typography
                                                    variant={'subtitle2'}
                                                >
                                                    <NavLink
                                                        to="/forgotpassword"
                                                        style={{
                                                            textDecoration:
                                                                'none',
                                                        }}
                                                    >
                                                        <Link
                                                            component={'a'}
                                                            color={'primary'}
                                                            href={'#'}
                                                            underline={'none'}
                                                        >
                                                            Forgot your
                                                            password?
                                                        </Link>
                                                    </NavLink>
                                                </Typography>
                                            </Box>
                                            <TextField
                                                label="Password *"
                                                variant="outlined"
                                                name={'password'}
                                                type={'password'}
                                                fullWidth
                                            />
                                        </Grid>
                                        <Grid item container xs={12}>
                                            <Box
                                                display="flex"
                                                flexDirection={{
                                                    xs: 'column',
                                                    sm: 'row',
                                                }}
                                                alignItems={{
                                                    xs: 'stretched',
                                                    sm: 'center',
                                                }}
                                                justifyContent={'space-between'}
                                                width={1}
                                                margin={'0 auto'}
                                            >
                                                <Box
                                                    marginBottom={{
                                                        xs: 1,
                                                        sm: 0,
                                                    }}
                                                >
                                                    <Typography
                                                        variant={'subtitle2'}
                                                    >
                                                        Don't have an account
                                                        yet?{' '}
                                                        <NavLink
                                                            to="/signup"
                                                            style={{
                                                                textDecoration:
                                                                    'none',
                                                            }}
                                                        >
                                                            <Link
                                                                component={'a'}
                                                                color={
                                                                    'primary'
                                                                }
                                                                underline={
                                                                    'none'
                                                                }
                                                            >
                                                                Sign up here.
                                                            </Link>
                                                        </NavLink>
                                                    </Typography>
                                                </Box>
                                                <Button
                                                    size={'large'}
                                                    variant={'contained'}
                                                    type={'submit'}
                                                >
                                                    Login
                                                </Button>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </form>
                            </Box>
                        </Container>
                    </Box>
                </Box>
            </Container>
        </Box>
    );
};

export default SignIn;
