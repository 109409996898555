/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Autocomplete, Box, Grid, TextField } from '@mui/material';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useAuth } from 'context/AuthContext';
import Order from 'types/Order';
import { OrderAction } from 'pages/constants';
import DashboardManager from 'Manager';

interface OrderBasicInformationComponentProps {
    order: Order;
    setOrder: React.Dispatch<OrderAction>;
}

const OrderBasicInformationComponent: React.FC<
    OrderBasicInformationComponentProps
> = ({ order, setOrder }) => {
    const [value, setValue] = React.useState<Date | null>(
        order.date ? new Date(order.date) : new Date(),
    );

    const [dealers, setDealers] = React.useState([]);

    const handleChange = (newValue: Date | null) => {
        setValue(newValue);
        setOrder({
            type: 'SET_DATE',
            payload: { value: newValue?.toDateString() ?? '' },
        });
    };

    const { currentUser } = useAuth();

    useEffect(() => {
        setOrder({
            type: 'SET_DATE',
            payload: { value: value?.toDateString() ?? '' },
        });
        const getDealers = async () => {
            setDealers(await DashboardManager.getDealers(currentUser.uid));
        };
        getDealers();
    }, []);

    return (
        <Box>
            <form>
                <Grid container spacing={4} sx={{}}>
                    <Grid item xs={12} sm={6}>
                        <Autocomplete
                            size={'small'}
                            id="dealer"
                            options={dealers}
                            fullWidth
                            defaultValue={order.dealer}
                            getOptionLabel={(option) => {
                                return option.name || '';
                            }}
                            renderInput={(params) => {
                                return <TextField {...params} label="Dealer" />;
                            }}
                            onChange={(e, value) =>
                                {
                                    setOrder({
                                        type: 'SET_DEALER',
                                        payload: { value },
                                    });
                                    const dealerId = value.id;
                                    console.log(dealerId);
                                    setOrder({
                                        type: 'SET_DEALER_ID',
                                        payload: { value: dealerId },
                                    })
                                }
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="sideMarks"
                            label="Sidemark *"
                            variant="outlined"
                            onChange={(e) =>
                                setOrder({
                                    type: 'SET_SIDEMARKS',
                                    payload: { value: e.target.value },
                                })
                            }
                            fullWidth
                            value={order.sideMarks || ''}
                            size={'small'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="job"
                            label="Job Number *"
                            variant="outlined"
                            value={order.job || ''}
                            onChange={(e) =>
                                setOrder({
                                    type: 'SET_JOB',
                                    payload: { value: e.target.value },
                                })
                            }
                            fullWidth
                            size={'small'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="color"
                            label="Color *"
                            variant="outlined"
                            value={order.color || ''}
                            onChange={(e) =>
                                setOrder({
                                    type: 'SET_COLOR',
                                    payload: { value: e.target.value },
                                })
                            }
                            fullWidth
                            size={'small'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <TextField
                            name="measuredBy"
                            label="Measure By"
                            variant="outlined"
                            value={order.measuredBy || ''}
                            onChange={(e) =>
                                setOrder({
                                    type: 'SET_MEASUREDBY',
                                    payload: { value: e.target.value },
                                })
                            }
                            fullWidth
                            size={'small'}
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <LocalizationProvider
                            dateAdapter={AdapterDateFns}
                            sx={{
                                width: '100%',
                            }}
                        >
                            <DesktopDatePicker
                                label="Date desktop"
                                inputFormat="MM/dd/yyyy"
                                value={value}
                                onChange={handleChange}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        sx={{ width: '100%' }}
                                        size={'small'}
                                    />
                                )}
                            />
                        </LocalizationProvider>
                    </Grid>
                </Grid>
            </form>
        </Box>
    );
};

export default OrderBasicInformationComponent;
