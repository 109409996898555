import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import DashboardManager from 'Manager';
import { useAuth } from 'context/AuthContext';
import { Alert, FormControlLabel, Switch, AlertTitle } from '@mui/material'; 
import { createUser } from '../../firebaseSetUp/firebaseSetUp';
import { AccountType } from 'types/User';
import { useMutation, useQueryClient } from 'react-query';
import LoadingButton from '@mui/lab/LoadingButton';

const validationSchema = yup.object({
    name: yup
        .string()
        .trim()
        .min(2, 'Please enter a valid name')
        .max(50, 'Please enter a valid name')
        .required('Please specify your name'),
    email: yup
        .string()
        .trim()
        .email('Please enter a valid email address')
        .required('Email is required.'),
    phoneNumber: yup
        .string()
        .trim()
        .min(2, 'Please enter a valid phone number')
        .max(80, 'Please enter a valid phone number')
        .required('Please specify your phone number'),
});

type AccountProps = {
    handleClose: () => void;
    isEditing?: boolean;
    account: any;
    accountType: AccountType
};

const Account: React.FC<AccountProps> = ({
    handleClose,
    isEditing = false,
    account = {},
    accountType
}): JSX.Element => {
    const { currentUser } = useAuth();

    const queryClient = useQueryClient();

    const initialValues = isEditing
        ? account
        : {
              name: '',
              email: '',
              phoneNumber: '',
              address: {
                streetName: '',
                city: '',
                state: '',
                zipCode: '',
                },
              active: true,
              accountType: accountType
          };

    const { mutate, isLoading, isError } = useMutation(
        async (userData: any) => {
            // eslint-disable-next-line no-useless-catch
            try {
                if (userData.id) {
                    await DashboardManager.updateAccount(userData.id, {
                    ...userData,
                    parentId: currentUser.uid,
                    accountType: accountType,
                    updatedAt: new Date().toISOString(),
                    });
                } else {
                    await createUser({ ...userData, createdAt: new Date().toISOString() });
                }
            } catch (error) {
                throw error;
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries([accountType]);
                handleClose();
            },
        }
    );

    const onSubmit = async (values) => {
        let newUserData;
        if (account) {
            newUserData = {...values, id: account.id, parentId: currentUser.uid, accountType};
        } else {
            newUserData = {
                name: values.name,
                email: values.email,
                password: values.email,
                parentId: currentUser.uid,
                address: {
                    streetName: values.address.streetName,
                    city: values.address.city,
                    zipCode: values.address.zipCode,
                    state: values.address.state
                },
                accountType,
                phoneNumber: values.phoneNumber,
                active: true
            }
        }

        mutate(newUserData);
    };

    const formik = useFormik({
        initialValues,
        validationSchema: validationSchema,
        onSubmit,
    });
    
    return (
        <>  
            {
                isError && 
                <Alert severity="error">
                    <AlertTitle>Error</AlertTitle>
                    Error Updating Account. Please Try A Different Email!
                </Alert>
            }
            <Typography variant="h6" fontWeight={700}>
                Account Information
            </Typography>
            <form onSubmit={formik.handleSubmit} style={{'height' : '80%', display: 'flex', flexDirection: 'column', justifyContent:'space-between'}}>
                <Grid item xs={12} sm={6}>
                    <FormControlLabel
                        control={<Switch />}
                        label="Active"
                        checked={formik.values.active}
                        onChange={formik.handleChange}
                        name="active"
                    />
                </Grid>
                <Grid container spacing={4}>
                    <Grid item xs={12}>
                        <Typography
                            variant={'subtitle2'}
                            sx={{ marginBottom: 2 }}
                            fontWeight={700}
                        >
                            Enter Name
                        </Typography>
                        <TextField
                            fullWidth
                            label="Name *"
                            variant="outlined"
                            name={'name'}
                            value={formik.values.name}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.name &&
                                Boolean(formik.errors.name)
                            }
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            helperText={
                                formik.touched.name && formik.errors.name
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant={'subtitle2'}
                            sx={{ marginBottom: 2 }}
                            fontWeight={700}
                        >
                            Enter Email
                        </Typography>
                        <TextField
                            fullWidth
                            label="Email *"
                            variant="outlined"
                            name={'email'}
                            value={formik.values.email}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.email &&
                                Boolean(formik.errors.email)
                            }
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            helperText={
                                formik.touched.email && formik.errors.email
                            }
                            disabled={isEditing}
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Typography
                            variant={'subtitle2'}
                            sx={{ marginBottom: 2 }}
                            fontWeight={700}
                        >
                            Enter Phone Number
                        </Typography>
                        <TextField
                            fullWidth
                            label="Phone Number *"
                            variant="outlined"
                            name={'phoneNumber'}
                            value={formik.values.phoneNumber}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.phoneNumber &&
                                Boolean(formik.errors.phoneNumber)
                            }
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            helperText={
                                formik.touched.phoneNumber &&
                                formik.errors.phoneNumber
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    {/* <Grid item xs={12}>
                        <Typography
                            variant={'subtitle2'}
                            sx={{ marginBottom: 2 }}
                            fontWeight={700}
                        >
                            Enter your address
                        </Typography>
                        <TextField
                            label="Address *"
                            variant="outlined"
                            name={'address'}
                            fullWidth
                            value={formik.values.address}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.address &&
                                Boolean(formik.errors.address)
                            }
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            helperText={
                                formik.touched.address && formik.errors.address
                            }
                        />
                    </Grid> */}
                    <Grid item xs={12} sm={6}>
                        <Typography
                            variant={'subtitle2'}
                            sx={{ marginBottom: 2 }}
                            fontWeight={700}
                        >
                            Street Name
                        </Typography>
                        <TextField
                            label="Street Name *"
                            variant="outlined"
                            name={'address.streetName'}
                            fullWidth
                            value={formik.values.address.streetName}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.streetName &&
                                Boolean(formik.errors.streetName)
                            }
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            helperText={
                                formik.touched.streetName &&
                                formik.errors.streetName
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography
                            variant={'subtitle2'}
                            sx={{ marginBottom: 2 }}
                            fontWeight={700}
                        >
                            City
                        </Typography>
                        <TextField
                            label="City *"
                            variant="outlined"
                            name={'address.city'}
                            fullWidth
                            value={formik.values.address.city}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.city &&
                                Boolean(formik.errors.city)
                            }
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            helperText={
                                formik.touched.city && formik.errors.city
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography
                            variant={'subtitle2'}
                            sx={{ marginBottom: 2 }}
                            fontWeight={700}
                        >
                            State
                        </Typography>
                        <TextField
                            label="State *"
                            variant="outlined"
                            name={'address.state'}
                            fullWidth
                            value={formik.values.address.state}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.state &&
                                Boolean(formik.errors.state)
                            }
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            helperText={
                                formik.touched.state && formik.errors.state
                            }
                        />
                    </Grid>
                    <Grid item xs={12} sm={6}>
                        <Typography
                            variant={'subtitle2'}
                            sx={{ marginBottom: 2 }}
                            fontWeight={700}
                        >
                            Zip Code
                        </Typography>
                        <TextField
                            label="Zip Code *"
                            variant="outlined"
                            name={'address.zipCode'}
                            fullWidth
                            value={formik.values.address.zipCode}
                            onChange={formik.handleChange}
                            error={
                                formik.touched.zipCode &&
                                Boolean(formik.errors.zipCode)
                            }
                            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                            // @ts-ignore
                            helperText={
                                formik.touched.zipCode && formik.errors.zipCode
                            }
                        />
                    </Grid>
                </Grid>
                <Box
                    display="flex"
                    flexDirection={{ xs: 'column', sm: 'row' }}
                    alignItems={{
                        xs: 'stretched',
                        sm: 'center',
                    }}
                    justifyContent={'space-between'}
                    width={1}
                    margin={'0 auto'}
                >
                    <Button
                        size={'large'}
                        variant={'contained'}
                        onClick={handleClose}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        color="primary"
                        variant="contained"
                        type="submit"
                        loading={isLoading}
                    >
                        {isEditing ? 'Save' : 'Submit'}
                    </LoadingButton>
                </Box>
            </form>
        </>
    );
};

export default Account;
