import React from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useAuth } from 'context/AuthContext';
import { Alert, Container } from '@mui/material'; 
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { DataGridPro, GridColDef, useGridApiRef } from '@mui/x-data-grid-pro';
import Order, { OrderType } from 'types/Order';
import { useUserInfo } from 'context/UserInfoContext';
import { AccountType } from 'types/User';
import { useMutation, useQueryClient } from 'react-query';
import { firestore } from 'firebaseSetUp/firebaseSetUp';
import { doc, updateDoc } from 'firebase/firestore';
import WindowDataProcessingUtils from 'pages/utils/WindowDataProcessingUtils';
import DashboardManager from 'Manager';

export function CustomFooterStatusComponent(props: {apiRef}) {
    
    const calculateTotal = (apiRef) => {
        const totalValues = {
            totalQuantity: 0,
            totalKg: 0,
        }
        apiRef.current.getAllRowIds().forEach(
            (id) => {
                const totalMaterialLineItem : TotalMaterialLineItem = {
                    id: 0,
                    description: '',
                    profile: '',
                    quantity: 0,
                };
                totalValues.totalQuantity += apiRef.current.getRow(id).quantity;
                totalValues.totalKg += Number(apiRef.current.getRow(id).quantity) * Number(apiRef.current.getRow(id).GW);
                return totalMaterialLineItem;
            }
        )

        totalValues.totalKg = WindowDataProcessingUtils.roundUp(totalValues.totalKg, 2);

        return totalValues;
    }

    return (
        <Box sx={{display:'flex', justifyContent:'flex-end', marginRight: '50px', mt: '10px', mb: '10px'}}>
            <Typography variant="h6" component="h2"> Total Quantity: {calculateTotal(props.apiRef).totalQuantity} boxes; Total Weight : {calculateTotal(props.apiRef).totalKg} kg </Typography>
        </Box>   
    );
  }

interface TotalMaterialLineItem {
    id: number,
    description: string,
    profile: string,
    quantity: number,
}

export interface PurchaseOrder extends Order {
    id?: string,
    orderNumber: number;
    userId: string;
    orderType: OrderType;
    orderStatus?: Array<string>;

    customerName?: string,
    date: string;
    shipping: string;
    contactPerson?: string,
    address?: {
        streetName?: string,
        city?: string,
        state?: string,
        zipCode?: string
    }
    phoneNumber?: string,
    fax?: string

    totalOrderBoxes?: Array<TotalMaterialLineItem>
}

const validationSchema = yup.object({
    name: yup
        .string()
        .trim()
        .min(2, 'Please enter a valid name')
        .max(50, 'Please enter a valid name')
        .required('Please specify your name'),
    email: yup
        .string()
        .trim()
        .email('Please enter a valid email address')
        .required('Email is required.'),
    phoneNumber: yup
        .string()
        .trim()
        .min(2, 'Please enter a valid phone number')
        .max(80, 'Please enter a valid phone number')
        .required('Please specify your phone number'),
});

const columns: GridColDef[] = [
    { 
      field: 'id', 
      headerName: 'No.', 
      width: 20,     
      disableColumnMenu: true,
      },
    {
      field: 'description',
      headerName: 'Description',
      width: 200,     
      disableColumnMenu: true,
    },
    {
      field: 'profile',
      headerName: 'Profile',
      width: 150,
      editable: true,
    },
    {
      field: 'length',
      headerName: 'Length (in)',
      width: 100,
      disableColumnMenu: true,
    },
    {
      field: 'pieces',
      headerName: 'Pcs.',
      type: 'number',
      width: 50,
      disableColumnMenu: true,
    },
    {
      field: 'quantity',
      headerName: 'Qty.',
      type: 'number',
      width: 70,
      editable: true,
    },
    {
      field: 'boxLength',
      headerName: 'Box Length',
      type: 'number',
      width: 100,
      disableColumnMenu: true,
    },
    {
      field: 'boxWidth',
      headerName: 'Box Width',
      type: 'number',
      width: 100,
      disableColumnMenu: true,
    },
    {
      field: 'boxHeight',
      headerName: 'Box Height',
      type: 'number',
      width: 100,
      disableColumnMenu: true,
    },
    {
      field: 'GW',
      headerName: 'Kg./Box',
      type: 'number',
      width: 80,
      disableColumnMenu: true,
    },
    {
      field: 'total',
      headerName: 'Total (kg)',
      type: 'number',
      width: 100,
      valueGetter: (params) => {
        return `${Number(WindowDataProcessingUtils.roundUp(params.row.GW * params.row.quantity, 2))}`;
    },
    },
  ];

export const purchaseOrderForm = [
    { id: 1, description: '3.5" TB Rail', profile: '', length: '144', pieces: '8', quantity: 0, boxLength: '145.67', boxWidth: '7.60', boxHeight: '3.50', GW: '26.42', total: 0 },
    { id: 2, description: '5.5" TB Rail', profile: '', length: '144', pieces: '6', quantity: 0, boxLength: '145.67', boxWidth: '6.10', boxHeight: '4.45', GW: '28.13', total: 0  },
    { id: 3, description: '3" Center Rail', profile: '', length: '144', pieces: '8', quantity: 0, boxLength: '145.67', boxWidth: '6.61', boxHeight: '4.45', GW: '22.9', total: 0 },
    { id: 4, description: '3"5 Louver', profile: '', length: '144', pieces: '10', quantity: 0, boxLength: '145.67', boxWidth: '5.71', boxHeight: '4.13', GW: '16.94', total: 0},
    { id: 5, description: 'Square Stile Pre-drill hole', profile: '', length: '144', pieces: '12' , quantity: 0, boxLength: '145.67', boxWidth: '9.33', boxHeight: '3.11', GW: '25.13', total: 0},
    { id: 6, description: 'L Frame', profile: '', length: '150', pieces: '10', quantity: 0, boxLength: '151.67', boxWidth: '10.24', boxHeight: '2.60', GW: '23.94', total: 0},
    { id: 7, description: 'Decor Z Frame', profile: '', length: '150', pieces: '8', quantity: 0, boxLength: '151.67', boxWidth: '12.40', boxHeight: '4.92', GW: '28.66', total: 0},
    { id: 8, description: 'Cover for Stile', profile: '', length: '144', pieces: '140', quantity: 0, boxLength: '145.67', boxWidth: '9.06', boxHeight: '2.76', GW: '22.19', total:0  },
    { id: 9, description: 'Austragal Cover For Stile', length: '144', pieces: '50', profile: '', quantity: 0, boxLength: '145.67', boxWidth: '6.10', boxHeight: '2.36', GW: '18.73', total: 0 },
    { id: 10, description: 'U Shap for PIN', profile: '', length: '144', pieces: '60', quantity: 0, boxLength: '145.67', boxWidth: '2.95', boxHeight: '2.17', GW: '19.79', total: 0 },
    { id: 11, description: 'Insert for TB Rail', profile: '', length: '144', pieces: '20', quantity: 0, boxLength: '145.67', boxWidth: '4.96', boxHeight: '3.62', GW: '21.53', total: 0  },
    { id: 12, description: 'Rear Control Rod Pre-drill hole', profile: '', length: '144', pieces: '12', quantity: 0, boxLength: '145.67', boxWidth: '3.78', boxHeight: '2.40', GW: '23.01', total:0 },
  ];

type PurchaseOrderComponentProps = {
    isEdit ?: boolean,
    purchaseOrder?: PurchaseOrder,
    handleCloseModal?: () => void,
}
const PurchaseOrderComponent: React.FC<PurchaseOrderComponentProps> = ({
    isEdit = false,
    handleCloseModal,
    purchaseOrder = {
        id: '',
        orderNumber: 0,
        userId: '',
        customerName: '',
        date: '',
        shipping: '',
        contactPerson: '',
        address: {
            streetName: '',
            city: '',
            state: '',
            zipCode: ''
        },
        phoneNumber: '',
        fax: '',
        totalOrderBoxes: purchaseOrderForm
    }
}): JSX.Element => {
    const { currentUser } = useAuth();
    const { userInfo, parentInfo } = useUserInfo();
    const apiRef = useGridApiRef();
    
    const queryClient = useQueryClient();

    if (purchaseOrder?.totalOrderBoxes) {
        purchaseOrder.totalOrderBoxes = purchaseOrderForm.map(
            (row) => {
                const newRow = purchaseOrder.totalOrderBoxes.find(e => e.id === row.id);
                return {
                    ...row,
                    quantity: Number(newRow.quantity),
                    profile: newRow.profile
                }
            }
        )
    }

    const onSubmit = async (values) => {
        savingPurchaseOrder();    
    };

    const formik = useFormik({
        initialValues: purchaseOrder,
        validationSchema: validationSchema,
        onSubmit,
    });

    const purchaseOrderMutation = useMutation(
        async (purchaseOrder: PurchaseOrder) => {
            try {
                if (purchaseOrder.id) {
                    await updateDoc(doc(firestore, 'orders', purchaseOrder.id), {...purchaseOrder, updatedAt: new Date().toISOString()});
                } else {
                    await DashboardManager.saveOrder(purchaseOrder)
                }
            } catch (e) {
                console.error('Error adding document: ', e);
            }
        },
        {
            onSuccess: () => {
                queryClient.invalidateQueries(['purchaseOrders']);
                handleCloseModal();
            },
        },
    );
    
    const savingPurchaseOrder = async () => {
        const newPurchaseOrder: PurchaseOrder = {
            id: purchaseOrder.id || '',
            orderNumber: isEdit ? purchaseOrder.orderNumber : 0,
            userId: isEdit && userInfo.accountType === AccountType.DISTRIBUTOR && purchaseOrder.userId ? purchaseOrder.userId : currentUser.uid,
            orderType: OrderType.TOTAL_MATERIAL,
        
            customerName: '',
            date: '',
            shipping: '',
            contactPerson: '',
            address: {
                streetName: '',
                city: '',
                state: '',
                zipCode: ''
            },
            phoneNumber: '',
            fax: '',
        
            totalOrderBoxes: null
        };
        newPurchaseOrder.address = formik.values.address;
        newPurchaseOrder.shipping = formik.values.shipping;
        newPurchaseOrder.date = formik.values.date;
        newPurchaseOrder.customerName = formik.values.customerName;
        newPurchaseOrder.phoneNumber = formik.values.phoneNumber;
        newPurchaseOrder.fax = formik.values.fax;
        newPurchaseOrder.contactPerson = formik.values.contactPerson;
        newPurchaseOrder.totalOrderBoxes = apiRef.current.getAllRowIds().map(
            (id) => {
                const totalMaterialLineItem : TotalMaterialLineItem = {
                    id: 0,
                    description: '',
                    profile: '',
                    quantity: 0,
                };
                totalMaterialLineItem.id = +id;
                totalMaterialLineItem.profile = apiRef.current.getRow(id).profile;
                totalMaterialLineItem.quantity = apiRef.current.getRow(id).quantity;
                totalMaterialLineItem.description = apiRef.current.getRow(id).description;
                return totalMaterialLineItem;
            }
        )

        purchaseOrderMutation.mutate(newPurchaseOrder);

        if (handleCloseModal) {
            handleCloseModal();
        }
    }

    if (userInfo.accountType === AccountType.FABRICATOR && !parentInfo) {
        return <> This Account Must be Associated With A Distributor </>
    }

    return (
        <Container>
        <Typography variant="h5" gutterBottom fontWeight={700}>
            Purchase Order
        </Typography>

        <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={4}>
                <Grid item xs={12}>
                    <TextField
                            size='small'
                            label="Distributor"
                            variant="outlined"
                            value={`${userInfo.accountType === AccountType.DISTRIBUTOR ? userInfo?.name : parentInfo?.name || ''}`}
                            fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        size='small'
                        label="Customer Name *"
                        variant="outlined"
                        name={'customerName'}
                        value={formik.values.customerName}
                        onChange={formik.handleChange}
                        error={false}
                        fullWidth
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        size='small'
                        label="Date *"
                        variant="outlined"
                        name={'date'}
                        value={formik.values.date}
                        onChange={formik.handleChange}
                        error={false}
                        fullWidth
                    />
                </Grid>           
                <Grid item xs={12} sm={6}>
                    <FormControl fullWidth>
                        <InputLabel id="select-label">Shipping</InputLabel>
                        <Select
                            size='small'
                            labelId="select-label"
                            id="simple-select"
                            value={formik.values.shipping}
                            label="Shipping"
                            name={'shipping'}
                            onChange={formik.handleChange}
                        >                            
                            <MenuItem value='delivery'>Delivery</MenuItem>
                            <MenuItem value='pickup'>Pick Up</MenuItem>
                            <MenuItem value='trucking'>Trucking</MenuItem>
                        </Select>
                    </FormControl>
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        size='small'
                        label="Contact Person"
                        variant="outlined"
                        name={'contactPerson'}
                        fullWidth
                        value={formik.values.contactPerson}
                        onChange={formik.handleChange}
                        error={false}
                    />
                </Grid>

                <Grid item xs={12} sm={6}>
                    <TextField
                        size='small'
                        label="Street Name *"
                        variant="outlined"
                        name={'address.streetName'}
                        fullWidth
                        value={formik.values.address.streetName}
                        onChange={formik.handleChange}
                        error={false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        size='small'
                        label="City *"
                        variant="outlined"
                        name={'address.city'}
                        fullWidth
                        value={formik.values.address.city}
                        onChange={formik.handleChange}
                        error={false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        size='small'
                        label="State *"
                        variant="outlined"
                        name={'address.state'}
                        fullWidth
                        value={formik.values.address.state}
                        onChange={formik.handleChange}
                        error={false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}>
                    <TextField
                        size='small'
                        label="Zip Code *"
                        variant="outlined"
                        name={'address.zipCode'}
                        fullWidth
                        value={formik.values.address.zipCode}
                        onChange={formik.handleChange}
                        error={false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}> 
                    <TextField
                        size='small'
                        fullWidth
                        label="Phone Number *"
                        variant="outlined"
                        name={'phoneNumber'}
                        value={formik.values.phoneNumber}
                        onChange={formik.handleChange}
                        error={false}
                    />
                </Grid>
                <Grid item xs={12} sm={6}> 
                    <TextField
                        size='small'
                        fullWidth
                        label="Fax *"
                        variant="outlined"
                        name={'fax'}
                        value={formik.values.fax}
                        onChange={formik.handleChange}
                        error={false}
                    />
                </Grid>
            </Grid>
        </form>
        
        <Box
            sx={{
                mt: '25px',
                mb: '25px'
            }}
        >            
            <Alert severity="info" sx={{mb: '5px'}}> Please fill in the quantity for each item! Total weight and total boxes will be automatically calculated for you.</Alert>

            <DataGridPro
                sx={{
                    boxShadow: 2,
                    border: 2,
                    borderColor: 'primary.light',
                    overflow: 'auto',       
                }}
                apiRef={apiRef}
                rows={formik.values.totalOrderBoxes}
                columns={columns}
                hideFooterPagination
                hideFooterSelectedRowCount
                components={{
                    Footer: CustomFooterStatusComponent
                }}
                componentsProps={{
                    footer: { apiRef },
                }}
                autoHeight
                density='compact'
                throttleRowsMs={1}
            />
        </Box>     

        <Box
            display="flex"
            flexDirection={{ xs: 'column', sm: 'row' }}
            alignItems={{
                xs: 'stretched',
                sm: 'center',
            }}
            justifyContent={'space-between'}
            width={1}
            margin={'0 auto'}
        >
            <Button
                size={'large'}
                variant={'contained'}
                onClick={handleCloseModal}
            >
                Cancel
            </Button>
            {
                <Button
                color="primary"
                variant="contained"
                type="submit"
                onClick={savingPurchaseOrder}
            >
                Save Purchase Order
            </Button>
            }

        </Box>
        </Container>
    );
};

export default PurchaseOrderComponent;
