import { Box } from '@mui/material';
import React from 'react';
import Window from '../types/Window';
import Order from 'types/Order';
import WindowsContainerComponent from './WindowsContainerComponent';
import { OrderAction } from '../pages/constants';

interface OrderWizardWindowsComponentProps {
    order: Order;
    setOrder: React.Dispatch<OrderAction>;
}

const OrderWindowsComponent: React.FC<OrderWizardWindowsComponentProps> = ({
    order,
    setOrder,
}) => {
    const addWindow = (window: Window) => {
        setOrder({ type: 'ADD_WINDOW', payload: { value: window } });
    };

    const deleteWindow = (windowId: string) => {
        setOrder({ type: 'DELETE_WINDOW', payload: { value: windowId } });
    };

    const editWindow = (window: Window) => {
        setOrder({ type: 'EDIT_WINDOW', payload: { value: window } });
    };

    return (
        <Box sx={{}}>
            <WindowsContainerComponent
                windows={order.windows}
                addWindow={addWindow}
                deleteWindow={deleteWindow}
                editWindow={editWindow}
            ></WindowsContainerComponent>
        </Box>
    );
};

export default OrderWindowsComponent;
