import * as React from 'react';
import Box from '@mui/material/Box';
import {
    DataGridPro,
    GridColDef,
    GridRenderCellParams,
    GridToolbar,
} from '@mui/x-data-grid-pro';
import OrderDashboardManager from 'Manager';
import OpenInFullIcon from '@mui/icons-material/OpenInFull';
import { Button, LinearProgress, Modal } from '@mui/material';
import Order, { OrderType } from 'types/Order';
import { useQuery } from 'react-query';
import PurchaseOrderComponent, { PurchaseOrder, purchaseOrderForm } from 'pages/PurchaseOrder/PurchaseOrderComponent';
import { useAuth } from 'context/AuthContext';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { useUserInfo } from 'context/UserInfoContext';
import { AccountType } from 'types/User';
import WindowDataProcessingUtils from 'pages/utils/WindowDataProcessingUtils';

const orderColumns: (
    handleSetOpen: () => void,
    handleSetOrderNumber: (order: Order) => void,
) => GridColDef[] = (handleSetOpen, handleSetOrderNumber) => {
    return [
        {
            field: 'edit',
            headerName: 'Order Details',
            width: 150,
            renderCell: (params: GridRenderCellParams) => {
                return (
                    <Button
                        sx={{
                            width: '100%',
                        }}
                        onClick={() => {
                            handleSetOrderNumber(params.row);
                            handleSetOpen();
                        }}
                        variant="text"
                        startIcon={
                            <OpenInFullIcon color="primary"></OpenInFullIcon>
                        }
                    >
                        Order Detail
                    </Button>
                );
            },
        },
        {
            field: 'date',
            headerName: 'Created On',
            width: 150,
            type: 'date',
        },
        {
            field: 'orderNumber',
            headerName: 'Order Number',
            width: 150,
        },
        {
            field: 'orderOwnerName',
            headerName: 'Order Owner',
            width: 150,
        },
        {
            field: 'customerName',
            headerName: 'Customer Name',
            width: 150,
        },
        {
            field: 'shipping',
            headerName: 'Shipping',
            width: 150,
        },
        {
            field: 'contactPerson',
            headerName: 'Contact Person',
            width: 150,
        },
        {
            field: 'totalQuantity',
            headerName: 'Quantity (Boxes)',
            width: 150,
            valueGetter: (params) => {
                return `${params.row.totalQuantity}`;
            },
        },
        {
            field: 'totalKg',
            headerName: 'Weight (Kg)',
            width: 150,
            valueGetter: (params) => {
                return `${params.row.totalKg}`;
            },
        },
        {
            field: 'address',
            headerName: 'Address',
            width: 300,
            valueGetter: (params) => {
                if (params.row.address) {
                    return `${params.row.address.streetName} ${params.row.address.city} ${params.row.address.state} ${params.row.address.zipCode}`;
                } else {
                    return '';
                }
            },
        },
    ];
};

type PurchaseOrdersComponentProps = {
    isDistributor?: boolean
}

const PurchaseOrdersComponent: React.FC<PurchaseOrdersComponentProps> = ({isDistributor =  false}) => {
    const { currentUser } = useAuth();
    const { userInfo, parentInfo } = useUserInfo();

    const [isAddingNewPurchaseOrder, setIsAddingNewPurchaseOrder] = React.useState<boolean>(false);

    const transformPurchaseOrder = (orders) => {
        return orders.map((order) => {
            let totalQuantity = 0, totalKg= 0;

            order?.totalOrderBoxes?.forEach(
                (e) => {
                    totalQuantity += Number(e.quantity)
                    totalKg += Number(purchaseOrderForm.find(({id}) => e.id === id).GW) * e.quantity
                }
            )

            return {
                ...order,
                totalQuantity,
                totalKg: WindowDataProcessingUtils.roundUp(totalKg, 2),
                orderOwnerName: order.orderOwnerName
            };
        });
    };

    const {
        isLoading,
        isError,
        data: orders,
    } = useQuery(
        'purchaseOrders',
        async () => {
            if (isDistributor) {
                return await OrderDashboardManager.getOrdersForUserAndChildren(currentUser.uid, OrderType.TOTAL_MATERIAL);
            } else {
                return await OrderDashboardManager.getOrdersForUser(currentUser.uid, OrderType.TOTAL_MATERIAL);
            }
        },
        {
            select: transformPurchaseOrder
        },
    );
    
    const [open, setOpen] = React.useState(false);

    const handleClose = () => {
        setOpen(false);
        setIsAddingNewPurchaseOrder(false);
    };

    const [selectedOrder, setSelectedOrder] = React.useState<PurchaseOrder>({
        id: '',
        userId: '',
        shipping: '',
        orderType: OrderType.TOTAL_MATERIAL,
        date: '',
        orderNumber: 0,
        address: {
            streetName: '',
            city: '',
            zipCode: '',
            state: '',
        }
    });

    React.useEffect(
        () => {
            return null;
        }, [selectedOrder]
    )

    if (isLoading) {
        return <LinearProgress />;
    }

    if (isError) {
        return <span>Error</span>;
    }

    if (isLoading) {
        return <span>Is Loading</span>;
    }

    return (
        <React.Fragment>
            <Box
                sx={{
                    width: '90%',
                    m: 'auto',
                    mb:'10px'
                }}
            >
                {
                    userInfo.accountType === AccountType.FABRICATOR && parentInfo && 
                    <Button
                        variant="contained"
                        startIcon={<AddCircleOutlineIcon />}
                        onClick={() => {
                            setIsAddingNewPurchaseOrder(true);
                            setOpen(true);
                        }}
                    >
                        Add New Purchase Order
                    </Button>
                }
            </Box>
            <Box
                sx={{
                    height: '80vh',
                    width: '90%',
                    m: 'auto',
                }}
            >
                <DataGridPro
                    sx={{
                        boxShadow: 2,
                        border: 2,
                        borderColor: 'primary.light',
                        '& .MuiDataGrid-cell:hover': {
                            color: 'primary.main',
                        },
                        overflow: 'auto',       
                    }}
                    initialState={{
                        columns: {
                            columnVisibilityModel: {
                                // Hide columns status and traderName, the other columns will remain visible
                                job: false,
                                color: false,
                                sideMarks: false,
                            },
                        },
                    }}
                    getRowId={(row) => row.orderNumber || row.id}
                    columns={orderColumns(
                        () => setOpen(true),
                        (order: PurchaseOrder) => {
                            setSelectedOrder(order)
                        },
                    )}
                    rows={orders}
                    loading={!orders}
                    rowHeight={38}
                    checkboxSelection
                    disableSelectionOnClick
                    components={{ Toolbar: GridToolbar }}
                    componentsProps={{
                        toolbar: { showQuickFilter: true },
                    }}
                />
            </Box>
            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box
                    sx={{
                        position: 'absolute' as const,
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        bgcolor: 'background.paper',
                        border: '2px #000',
                        p: 4,
                        width: '80%',
                        height: '90%',
                        overflow: 'scroll'
                    }}
                >
                    {
                        isAddingNewPurchaseOrder ? 
                        <PurchaseOrderComponent handleCloseModal={handleClose}></PurchaseOrderComponent> :
                        <PurchaseOrderComponent
                            isEdit={isDistributor ? false : true}
                            purchaseOrder={selectedOrder}
                            handleCloseModal={handleClose}
                        ></PurchaseOrderComponent>
                    }
                </Box>
            </Modal>
        </React.Fragment>
    );
}

export default PurchaseOrdersComponent;