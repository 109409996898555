import { Routes as ReactRoutes, Route } from 'react-router-dom';
import React from 'react';
import Private from './components/Private';
import DealerOrdersComponent from 'pages/DealerOrders/DealerOrdersComponent';
import Accounts from 'pages/DealerAccounts/AccountsComponent';
import { UserInfoProvider } from 'context/UserInfoContext';
import PurchaseOrdersComponent from 'pages/PurchaseOrders/PurchaseOrdersComponent';
import SignUp from 'pages/SignUp/SignUp';
import SignIn from 'pages/SignIn/SignIn';
import ForgotPassword from 'pages/ForgotPassword/ForgotPassword';
import { useAuth } from 'context/AuthContext';
import DefaultPage from 'pages/DefaultPage/DefaultPage';
import { AccountType } from 'types/User';

const RoutePaths = (): JSX.Element => {

    const { currentUser } = useAuth();

    return (
        <ReactRoutes>
            <Route
                path="/"
                element={
                    <Private>
                        <UserInfoProvider>
                            <DefaultPage></DefaultPage>
                        </UserInfoProvider>
                    </Private> 
                }
            />
            <Route
                path="/fabricators"
                element={
                    <Private>
                        <UserInfoProvider>
                            <Accounts accountType={AccountType.FABRICATOR}></Accounts>
                        </UserInfoProvider>
                    </Private> 
                }
            />
            <Route
                path="/purchase_orders"
                element={
                    <Private>
                        <UserInfoProvider>
                            <PurchaseOrdersComponent isDistributor={false}></PurchaseOrdersComponent>
                        </UserInfoProvider>
                    </Private>
                }
            />
            <Route
                path="/fabricator_orders"
                element={
                    <Private>
                        <UserInfoProvider>
                            <PurchaseOrdersComponent isDistributor={true}></PurchaseOrdersComponent>
                        </UserInfoProvider>
                    </Private>
                }
            />
            <Route
                path="/dealers"
                element={
                    <Private>
                        <UserInfoProvider>
                            <Accounts accountType={AccountType.DEALER}></Accounts>
                        </UserInfoProvider>
                    </Private>
                }
            />
            <Route
                path="/dealer_orders"
                element={
                    <Private>
                        <UserInfoProvider>
                            <DealerOrdersComponent></DealerOrdersComponent>
                        </UserInfoProvider>
                    </Private>
                }
            />
            <Route path="/signin" element={<SignIn></SignIn>} />
            <Route path="/signup" element={<SignUp></SignUp>} />
            <Route
                path="/forgotpassword"
                element={<ForgotPassword></ForgotPassword>}
            />
            <Route
                path="*"
                element={!currentUser ? <SignIn></SignIn> : <DefaultPage></DefaultPage>}
            />
        </ReactRoutes>
    );
};

export default RoutePaths;
