import InvoicePage from './components/InvoicePage';
import { Box, Button, Modal } from '@mui/material';
import React from 'react';
import './scss/main.scss';
import CloseIcon from '@mui/icons-material/Close';
import Order from 'types/Order';
import { User } from 'firebase/auth';
import { Invoice } from './data/types';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    width: '100%',
    height: '100%',
    overflow: 'scroll',
};

interface InvoiceGeneratorModalProps {
    open: boolean;
    handleClose: () => void;
    invoice: Invoice;
}

const InvoiceGeneratorModal: React.FC<InvoiceGeneratorModalProps> = ({
    open,
    handleClose,
    invoice,
}) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Button
                    variant="outlined"
                    startIcon={<CloseIcon />}
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        top: '70px',
                        right: '300px',
                    }}
                >
                    Close
                </Button>
                <div className="app">
                    <h1 className="center fs-30">React Invoice Generator</h1>
                    <InvoicePage data={invoice} />
                </div>
            </Box>
        </Modal>
    );
};

export default InvoiceGeneratorModal;
