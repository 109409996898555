import { Box, Modal } from '@mui/material';
import { PDFViewer } from '@react-pdf/renderer';
import React from 'react';
import Order from 'types/Order';
import PdfDocument from './pdf/PdfDocument';

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    width: '85%',
    height: '90%',
};

interface PrintModalComponentProps {
    open: boolean;
    handleClose: () => void;
    order: Order;
}

const PrintModalComponent: React.FC<PrintModalComponentProps> = ({
    open,
    handleClose,
    order,
}) => {
    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <PDFViewer
                    showToolbar
                    style={{
                        width: '100%',
                        height: '100%',
                    }}
                >
                    <PdfDocument {...order} />
                </PDFViewer>
            </Box>
        </Modal>
    );
};

export default PrintModalComponent;
