import React from 'react';
import {
    Document,
    Page,
    Text,
    View,
    StyleSheet,
    Image,
} from '@react-pdf/renderer';
import TableContainerPanel from './TablePanelComponent/TableContainerPanel';
import TableContainerFrame from './TableFrameComponent/TableContainerFrame';
import Order from 'types/Order';
import { LIST_IMAGES_LOCATION } from 'pages/constants';

// Create styles
const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 15,
        paddingLeft: 15,
        paddingRight: 15,
        paddingBottom: 15,
        lineHeight: 1.5,
        flexDirection: 'column',
    },
    section: {
        margin: 0,
        borderStyle: 'solid',
        border: '2px',
        borderColor: 'black',
        marginTop: '2px',
    },
    header: {
        display: 'flex',
        justifyContent: 'center',
        alignContent: 'center',
        width: '90%',
        padding: 10,
        borderStyle: 'solid',
        height: '20vh',
        border: '2px',
    },
    headerRow: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'flex-start',
        alignItems: 'flex-start',
        justifyContent: 'flex-start',
        gap: '10px',
        margin: 2,
        padding: 5,
    },
    headerInput: {
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'center',
        textDecoration: 'underline',
    },
    headerInputTitle: {
        fontSize: '15px',
    },
    headerInputContent: {
        fontSize: '20px',
    },
    sectionContent: {
        fontSize: '18px',
        marginRight: 100,
    },
    subSectionContent: {
        fontSize: '15px',
        marginRight: 50,
    },
});

const InputHeader = ({
    title,
    content,
}: {
    title: string;
    content: string;
}) => (
    <View style={styles.headerInput}>
        <Text style={styles.headerInputTitle}> {title} </Text>
        <Text style={styles.headerInputContent}> {content} </Text>
    </View>
);

const getImageLocation = (frameDesign: string) => {
    const topType = frameDesign
        ?.split(';')
        .find((s) => s.includes('TOP'))
        ?.split(':')[1]
        .trim();

    const bottomType = frameDesign
        ?.split(';')
        .find((s) => s.includes('BOTTOM'))
        ?.split(':')[1]
        .trim();

    const leftType = frameDesign
        ?.split(';')
        .find((s) => s.includes('LEFT'))
        ?.split(':')[1]
        .trim();

    const rightType = frameDesign
        ?.split(';')
        .find((s) => s.includes('RIGHT'))
        ?.split(':')[1]
        .trim();

    return `TOP${topType}RIGHT${rightType}BOTTOM${bottomType}LEFT${leftType}`;
};

// Create Document Component
const PdfDocument: React.FC<Order> = ({
    dealer,
    job,
    sideMarks,
    date,
    color,
    measuredBy,
    windows,
}) => {
    const categorizedItems: unknown = {};

    windows.forEach((item) => {
        const frameKindName = item.frameKind.name + '|' + item.frameDesign.name;
        const newItemFrameDesignName = item.frameKind.name.split('-')[1].trim();
        if (frameKindName && categorizedItems[frameKindName]) {
            const newItem = {
                ...item,
                frameKind: newItemFrameDesignName,
                id: categorizedItems[frameKindName].length + 1,
            };
            categorizedItems[frameKindName].push(newItem);
        } else {
            const newItem = {
                ...item,
                frameKind: newItemFrameDesignName,
                id: 1,
            };
            categorizedItems[frameKindName] = [newItem];
        }
    });

    const pageContents = Object.keys(categorizedItems).map(
        (frameDesign, key) => {
            return (
                <React.Fragment key={key}>
                    <Page size="A4" style={styles.page} orientation="landscape">
                        <View style={styles.header}>
                            <View style={styles.headerRow}>
                                <InputHeader
                                    title="Dealer:"
                                    content={dealer?.name || ''}
                                />
                                <InputHeader title="Job#" content={job} />
                            </View>
                            <View style={styles.headerRow}>
                                <InputHeader
                                    title="Side Mark:"
                                    content={sideMarks || ''}
                                />
                                <InputHeader title="Date" content={date} />
                            </View>
                            <View style={styles.headerRow}>
                                <InputHeader title="Color" content={color} />
                                <InputHeader
                                    title="Measured By:"
                                    content={measuredBy || ''}
                                />
                            </View>
                        </View>
                        <View style={styles.section}>
                            <View style={styles.headerRow}>
                                <Text style={styles.sectionContent}>
                                    Magic Tilt
                                </Text>
                                <Text style={styles.sectionContent}>
                                    {frameDesign
                                        .split('|')[0]
                                        .split('-')[0]
                                        .trim()}
                                </Text>
                                <Text style={styles.sectionContent}>
                                    Panels
                                </Text>
                                <Text style={styles.sectionContent}>
                                    {frameDesign
                                        .split('|')[0]
                                        .split('-')[1]
                                        .trim()}
                                </Text>
                                {LIST_IMAGES_LOCATION.includes(
                                    getImageLocation(
                                        frameDesign.split('|')[1].trim(),
                                    ),
                                ) ? (
                                    <Image
                                        style={{
                                            height: 100,
                                            width: 200,
                                        }}
                                        src={require(`../images/${getImageLocation(
                                            frameDesign.split('|')[1].trim(),
                                        )}.png`)}
                                    />
                                ) : (
                                    ''
                                )}
                            </View>
                        </View>
                        <TableContainerPanel
                            items={categorizedItems[frameDesign]}
                        />
                    </Page>
                    <Page size="A4" style={styles.page} orientation="landscape">
                        <View style={styles.header}>
                            <View style={styles.headerRow}>
                                <InputHeader
                                    title="Dealer:"
                                    content={dealer?.name || ''}
                                />
                                <InputHeader title="Job#" content={job} />
                            </View>
                            <View style={styles.headerRow}>
                                <InputHeader
                                    title="Side Mark:"
                                    content={sideMarks}
                                />
                                <InputHeader title="Date" content={date} />
                            </View>
                            <View style={styles.headerRow}>
                                <InputHeader title="Color" content={color} />
                                <InputHeader
                                    title="Measured By:"
                                    content={measuredBy}
                                />
                            </View>
                        </View>
                        <View style={styles.section}>
                            <View style={styles.headerRow}>
                                <Text style={styles.sectionContent}>
                                    Magic Tilt
                                </Text>
                                <Text style={styles.sectionContent}>
                                    {frameDesign
                                        .split('|')[0]
                                        .split('-')[0]
                                        .trim()}
                                </Text>
                                <Text style={styles.sectionContent}>Frame</Text>
                                <Text style={styles.sectionContent}>
                                    {frameDesign
                                        .split('|')[0]
                                        .split('-')[1]
                                        .trim()}
                                </Text>
                                {LIST_IMAGES_LOCATION.includes(
                                    getImageLocation(
                                        frameDesign.split('|')[1].trim(),
                                    ),
                                ) ? (
                                    <Image
                                        style={{
                                            height: 100,
                                            width: 200,
                                        }}
                                        src={require(`../images/${getImageLocation(
                                            frameDesign.split('|')[1].trim(),
                                        )}.png`)}
                                    />
                                ) : (
                                    ''
                                )}
                            </View>
                        </View>
                        <TableContainerFrame
                            items={categorizedItems[frameDesign]}
                        />
                    </Page>
                </React.Fragment>
            );
        },
    );

    return (
        <Document title="expo-window-sheet" author="Hien-Nguyen">
            {pageContents}
        </Document>
    );
};

export default PdfDocument;
