import React from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
  container: {
    flexDirection: 'row',
    borderBottomColor: '#bff0fd',
    backgroundColor: '#bff0fd',
    borderBottomWidth: 1,
    alignItems: 'center',
    height: 32,
    textAlign: 'center',
    flexGrow: 1,
    fontSize: '10px',
    fontStyle: 'bold',
  },
  no: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  inputStyle: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    backgroundColor: '#ff6a6a',
    height: '100%',
    textAlign: 'center',
  },
  louverSize: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
    backgroundColor: '#ff6a6a',
  },
  panelHeight: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  div: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  open: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  panels: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  panelWidth: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  topAndBottomRail: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  louverWidth: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  louvers: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  bottomRail: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  topRail: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  bottomLouvers: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  topLouvers: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  inHeight: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
  inWidth: {
    width: '7%',
    borderRightColor: borderColor,
    borderRightWidth: 1,
  },
});

const InvoiceTableHeader = () => (
  <View style={styles.container}>
    <Text style={styles.no}>No</Text>
    <Text style={styles.inputStyle}>Louver Size</Text>
    <Text style={styles.panelHeight}>Panel Height</Text>
    <Text style={styles.inputStyle}>Div</Text>
    <Text style={styles.open}>Open</Text>
    <Text style={styles.inputStyle}>#Panel</Text>
    <Text style={styles.panelWidth}>Panel Width</Text>
    <Text style={styles.topAndBottomRail}>T & B Rail Width</Text>
    <Text style={styles.louverWidth}> Louver Width </Text>
    <Text style={styles.louvers}> # Louver </Text>
    <Text style={styles.inputStyle}> B-Rail </Text>
    <Text style={styles.inputStyle}> T-Rail </Text>
    <Text style={styles.inputStyle}> Bottom Louvers </Text>
    <Text style={styles.inputStyle}> Top Louvers </Text>
    <Text style={styles.louvers}> in H </Text>
    <Text style={styles.louvers}> in W </Text>
  </View>
);

export default InvoiceTableHeader;
