/* eslint-disable @typescript-eslint/ban-ts-comment */
import React from 'react';
import { View, StyleSheet } from '@react-pdf/renderer';
import TableHeader from './TableHeaderPanel';
import TableRowPanel from './TableRowPanel';

const tableRowsCount = 11;

const styles = StyleSheet.create({
  tableContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginTop: 10,
    borderWidth: 1,
    borderColor: '#bff0fd',
  },
});

// @ts-ignore
const InvoiceItemsTable: React.FC<{ items: Array<DataRow> }> = ({ items }) => (
  <View style={styles.tableContainer}>
    <TableHeader />
    <TableRowPanel items={items} />
  </View>
);

export default InvoiceItemsTable;
