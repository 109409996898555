import {
    createUserWithEmailAndPassword,
    sendPasswordResetEmail,
    signInWithEmailAndPassword,
    signOut,
    Unsubscribe,
    User as FirebaseUser,
    UserCredential,
} from 'firebase/auth';
import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { auth } from '../firebaseSetUp/firebaseSetUp';
import { AccountType } from 'types/User';

export interface Address {
    streetName?: string;
    city?: string;
    state?: string;
    zipCode?: string;
}

export interface UserWithMetaData extends FirebaseUser {
    accountType?: AccountType;
    address?: Address;
    parentId?: string;
    id?: string;
    name?: string;
}

interface AuthContextValue {
    currentUser: UserWithMetaData | undefined;
    signup?: (email: string, password: string) => Promise<UserCredential>;
    signin?: (email: string, password: string) => Promise<UserCredential>;
    signout?: () => Promise<void>;
    resetPassword?: (email: string) => Promise<void>;
}

const defaultState: AuthContextValue = {
    currentUser: null,
};

interface AuthProviderProps {
    children?: ReactNode;
}

const AuthContext = React.createContext<AuthContextValue>(defaultState);

export const useAuth = () => {
    return useContext(AuthContext);
};

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
    const [currentUser, setCurrentUser] = useState<UserWithMetaData>();
    const [loading, setLoading] = useState<boolean>(true);

    function signup(email: string, password: string): Promise<UserCredential | null> {
        const signUpObject = createUserWithEmailAndPassword(
            auth,
            email,
            password,
        ).catch(
            (error) => {
                throw new Error(error.message)
            }
        );

        return signUpObject;
    }

    function signin(email: string, password: string): Promise<UserCredential> {
        return signInWithEmailAndPassword(auth, email, password);
    }

    function signout() {
        return signOut(auth);
    }

    function resetPassword(email: string) {
        return sendPasswordResetEmail(auth, email);
    }

    useEffect(() => {
        const unsubscribe: Unsubscribe = auth.onAuthStateChanged((user) => {
            setCurrentUser(user);
            setLoading(false);
        });

        return unsubscribe;
    }, []);

    const value: AuthContextValue = {
        signup,
        signin,
        signout,
        resetPassword,
        currentUser
    };

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    );
};
