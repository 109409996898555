import React from 'react';
import { Box, Modal } from '@mui/material';
import WindowComponent from './WindowComponent';
import Window from 'types/Window';

interface WindowModalComponentProps {
    window: Window;
    isWindowModalOpen: boolean;
    handleCloseWindowModal: () => void;
    addWindow: (window: Window) => void;
    editWindow: (window: Window) => void;
}

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
    width: '90%',
    height: '90%',
};

const WindowModalComponent: React.FC<WindowModalComponentProps> = ({
    window,
    isWindowModalOpen = false,
    handleCloseWindowModal,
    addWindow,
    editWindow,
}) => {
    return (
        <Modal
            open={isWindowModalOpen}
            onClose={handleCloseWindowModal}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <WindowComponent
                    window={window}
                    handleCloseWindowModal={handleCloseWindowModal}
                    addWindow={addWindow}
                    editWindow={editWindow}
                ></WindowComponent>
            </Box>
        </Modal>
    );
};

export default WindowModalComponent;
