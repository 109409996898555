/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { Fragment } from 'react';
import { Text, View, StyleSheet } from '@react-pdf/renderer';
import Window from '../../../types/Window';
import WindowDataProcessingUtils from 'pages/utils/WindowDataProcessingUtils';

const borderColor = '#90e5fc';
const styles = StyleSheet.create({
    row: {
        flexDirection: 'row',
        borderBottomColor: '#bff0fd',
        borderBottomWidth: 1,
        alignItems: 'center',
        height: 24,
        textAlign: 'center',
        flexGrow: 1,
        fontSize: '10px',
    },
    rate: {
        width: '7%',
        borderRightWidth: 1,
        borderRightColor: borderColor,
    },
    inputStyle: {
        width: '7%',
        borderRightColor: borderColor,
        borderRightWidth: 1,
        backgroundColor: '#ff6a6a',
        height: '100%',
        padding: '1px',
        marginTop: 1,
        paddingTop: 2,
    },
});

const TableRow: React.FC<{ items: Array<Window> }> = ({ items }) => {
    // @ts-ignore
    console.log(items);
    const rows = items.map((item) => (
        <View style={styles.row} key={item.id}>
            <Text style={styles.rate}>{item.id}</Text>
            <Text style={styles.inputStyle}>{item.louverSize}</Text>
            <Text style={styles.rate}>
                {WindowDataProcessingUtils.convertingNumberToFraction(
                    item.panelHeight,
                )}
            </Text>
            <Text style={styles.inputStyle}>
                {WindowDataProcessingUtils.convertingNumberToFraction(
                    item.divRail,
                )}
            </Text>
            <Text style={styles.rate}>{item.panelDesign.name}</Text>
            <Text style={styles.inputStyle}>{item.numberOfPanels.name}</Text>
            <Text style={styles.rate}>
                {WindowDataProcessingUtils.convertingNumberToFraction(
                    item.panelWidth,
                )}
            </Text>
            <Text style={styles.rate}>
                {WindowDataProcessingUtils.convertingNumberToFraction(
                    item.topAndBottomRailWidth,
                )}
            </Text>
            <Text style={styles.rate}>
                {WindowDataProcessingUtils.convertingNumberToFraction(
                    item.louverWidth,
                )}
            </Text>
            <Text style={styles.rate}>{item.totalLouvers}</Text>
            <Text style={styles.inputStyle}>
                {WindowDataProcessingUtils.convertingNumberToFraction(
                    Number(WindowDataProcessingUtils.convertingFractionToNumber(String(item.bottomRailHeight))) + 1/16,
                )}
            </Text>
            <Text style={styles.inputStyle}>
                {WindowDataProcessingUtils.convertingNumberToFraction(
                     Number(WindowDataProcessingUtils.convertingFractionToNumber(String(item.topRailHeight))) + 1/8,
                )}
            </Text>
            <Text style={styles.inputStyle}>
                {WindowDataProcessingUtils.convertingNumberToFraction(
                    item.bottomLouvers,
                )}
            </Text>
            <Text style={styles.inputStyle}>
                {WindowDataProcessingUtils.convertingNumberToFraction(
                    item.topLouvers,
                )}
            </Text>
            <Text style={styles.rate}>
                {WindowDataProcessingUtils.convertingNumberToFraction(
                    item.heightInFrame,
                )}
            </Text>
            <Text style={styles.rate}>
                {WindowDataProcessingUtils.convertingNumberToFraction(
                    item.widthInFrame,
                )}
            </Text>
        </View>
    ));
    return <Fragment>{rows}</Fragment>;
};

export default TableRow;
