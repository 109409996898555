import { Box } from '@mui/material';
import RoutePaths from 'RoutePaths';
import React from 'react';

const ContentWrapper = (): JSX.Element => {
    return (
        <Box p={4}>
            <Box width={1} height={1} minHeight={800} borderRadius={2}>
                <RoutePaths />
            </Box>
        </Box>
    );
};

export default ContentWrapper;
