import React from 'react';

export const fabricatorSideBarNavs = [
    {
        groupTitle: 'Dealer Accounts',
        id: 'dealer_accounts',
        pages: [
            {
                title: 'Dealers',
                href: '/dealers',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                    </svg>
                ),
            },
        ],
    },
    {
        groupTitle: 'Dealer Orders',
        id: 'dealer_orders',
        pages: [
            // {
            //     title: 'New Dealer Order',
            //     href: '/new_dealer_order',
            //     icon: (
            //         <svg
            //             xmlns="http://www.w3.org/2000/svg"
            //             width={24}
            //             height={24}
            //             fill="none"
            //             viewBox="0 0 24 24"
            //             stroke="currentColor"
            //         >
            //             <path
            //                 strokeLinecap="round"
            //                 strokeLinejoin="round"
            //                 strokeWidth={2}
            //                 d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
            //             />
            //         </svg>
            //     ),
            // },
            {
                title: 'Dealer Orders',
                href: '/dealer_orders',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M17 9V7a2 2 0 00-2-2H5a2 2 0 00-2 2v6a2 2 0 002 2h2m2 4h10a2 2 0 002-2v-6a2 2 0 00-2-2H9a2 2 0 00-2 2v6a2 2 0 002 2zm7-5a2 2 0 11-4 0 2 2 0 014 0z"
                        />
                    </svg>
                ),
            },
            // {
            //     title: 'New Purchase Order',
            //     href: '/new_purchase_order',
            //     icon: (
            //         <svg
            //             xmlns="http://www.w3.org/2000/svg"
            //             width={24}
            //             height={24}
            //             fill="none"
            //             viewBox="0 0 24 24"
            //             stroke="currentColor"
            //         >
            //             <path
            //                 strokeLinecap="round"
            //                 strokeLinejoin="round"
            //                 strokeWidth={2}
            //                 d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
            //             />
            //         </svg>
            //     ),
            // },
            {
                title: 'Purchase Orders',
                href: '/purchase_orders',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                        />
                    </svg>
                ),
            },
        ],
    },
];

export const distributorSideBarNavs = [
    {
        groupTitle: 'Accounts',
        id: 'accounts',
        pages: [
            {
                title: 'Fabricators',
                href: '/fabricators',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                    </svg>
                ),
            },
            {
                title: 'Dealers',
                href: '/dealers',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                    </svg>
                ),
            },
        ],
    },
    {
        groupTitle: 'Orders',
        id: 'orders',
        pages: [
            // {
            //     title: 'New Dealer Order',
            //     href: '/new_dealer_order',
            //     icon: (
            //         <svg
            //             xmlns="http://www.w3.org/2000/svg"
            //             width={24}
            //             height={24}
            //             fill="none"
            //             viewBox="0 0 24 24"
            //             stroke="currentColor"
            //         >
            //             <path
            //                 strokeLinecap="round"
            //                 strokeLinejoin="round"
            //                 strokeWidth={2}
            //                 d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
            //             />
            //         </svg>
            //     ),
            // },
            {
                title: 'Dealer Orders',
                href: '/dealer_orders',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                        />
                    </svg>
                ),
            },
            {
                title: 'Fabricator Orders',
                href: '/fabricator_orders',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M9 5H7a2 2 0 00-2 2v12a2 2 0 002 2h10a2 2 0 002-2V7a2 2 0 00-2-2h-2M9 5a2 2 0 002 2h2a2 2 0 002-2M9 5a2 2 0 012-2h2a2 2 0 012 2m-3 7h3m-3 4h3m-6-4h.01M9 16h.01"
                        />
                    </svg>
                ),
            },
        ],
    },
];

export const adminSidebars = [
    {
        groupTitle: 'Accounts',
        id: 'accounts',
        pages: [
            {
                title: 'Distributors',
                href: '/distributors',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                    </svg>
                ),
            },
            {
                title: 'Fabricators',
                href: '/fabricators',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                    </svg>
                ),
            },
            {
                title: 'Dealers',
                href: '/dealers',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                    </svg>
                ),
            },
        ],
    },
    {
        groupTitle: 'Settings',
        id: 'settings',
        pages: [
            {
                title: 'Calculation Settings',
                href: '/calculation_settings',
                icon: (
                    <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width={24}
                        height={24}
                        fill="none"
                        viewBox="0 0 24 24"
                        stroke="currentColor"
                    >
                        <path
                            strokeLinecap="round"
                            strokeLinejoin="round"
                            strokeWidth={2}
                            d="M12 4.354a4 4 0 110 5.292M15 21H3v-1a6 6 0 0112 0v1zm0 0h6v-1a6 6 0 00-9-5.197M13 7a4 4 0 11-8 0 4 4 0 018 0z"
                        />
                    </svg>
                ),
            },
        ],
    },
];

export const dealerSideBars = [
];

export default fabricatorSideBarNavs;
