import Window from './Window';

export interface TotalOrderMaterial {
    totalSQMaterial: number;
    totalLouverMaterial: number;
    totalTopRailWidthMaterial: number;
    totalBottomRailWidthMaterial: number;
    totalStileMaterial: number;
    totalCutAluminumMaterial: number;
    totalMagicTiltMaterial: number;
    frameMaterialType: {
        S: number;
        Z: number;
        OB: number;
    };
}

export interface Dealer {
    active: boolean;
    city: string;
    email: string;
    id: string;
    name: string;
    parentId: string;
    phoneNumber: string;
    state: string;
    streetName: string;
    zipCode: string;
}

export enum OrderType {
    WINDOW = 'WINDOW',
    TOTAL_MATERIAL = 'TOTAL_MATERIAL',
}

export default interface Order {
    // Uniquely Order ID
    id?: string;
    // To easily determine order number
    orderNumber: number;

    // Who order?
    userId?: string;

    // Who's the owner?
    orderOwnerName?: string;

    // WHo is this order for?
    dealerId?:string;
    
    // Which type?
    orderType: OrderType;

    // Date ordered
    date: string;
    
    editDate?: string;

    // Order for whom
    dealer?: Dealer;

    sideMarks?: string;
    job?: string;
    color?: string;
    measuredBy?: string;

    windows?: Array<Window>;

    orderStatus?: Array<string>;

    total?: TotalOrderMaterial;
}
