import React, { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import { useNavigate } from 'react-router-dom';
import { fabricatorSideBarNavs, adminSidebars, dealerSideBars, distributorSideBarNavs } from '../Navigations';
import { useAuth } from 'context/AuthContext';
import { AccountType } from 'types/User';
import DashboardManager from 'Manager';

const SidebarNav = (): JSX.Element => {
    const navigate = useNavigate();

    const { currentUser } = useAuth();

    const [navBars, setNavBars] = useState([]);
    
    useEffect(
        () => {
            const getUserMetaData = async () => {
                if (currentUser) {
                    const userMetaData = await DashboardManager.getUserMetaData(currentUser.uid);
                    if (userMetaData.accountType === AccountType.DISTRIBUTOR) {
                        setNavBars(distributorSideBarNavs)
                    } else if (userMetaData.accountType === AccountType.FABRICATOR) {
                        setNavBars(fabricatorSideBarNavs)
                    } else if (userMetaData.accountType === AccountType.DEALER) {
                        setNavBars(dealerSideBars)
                    } else {
                        setNavBars(adminSidebars)
                    }
                }
            }
            getUserMetaData();
        }, []
    )

    return (
        <Box padding={2}>
            {navBars.map((item, i) => (
                <Box key={i} marginBottom={3}>
                    <Typography
                        variant="caption"
                        color={'text.secondary'}
                        sx={{
                            fontWeight: 700,
                            textTransform: 'uppercase',
                            marginBottom: 1,
                            display: 'block',
                        }}
                    >
                        {item.groupTitle}
                    </Typography>
                    <Box>
                        {item.pages.map((p, i) => (
                            <Box marginBottom={1 / 2} key={i}>
                                <Button
                                    component={'a'}
                                    onClick={() => navigate(p.href)}
                                    fullWidth
                                    sx={{
                                        justifyContent: 'flex-start',
                                        color: 'text.primary',
                                    }}
                                    startIcon={p.icon || null}
                                >
                                    {p.title}
                                </Button>
                            </Box>
                        ))}
                    </Box>
                </Box>
            ))}
        </Box>
    );
};

export default SidebarNav;
