import * as React from 'react';
import {
    GridRenderEditCellParams,
    useGridApiContext,
} from '@mui/x-data-grid-pro';
import Select, { SelectProps } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import { MenuProps } from '@mui/material/Menu';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import ReportProblemIcon from '@mui/icons-material/ReportProblem';
import InfoIcon from '@mui/icons-material/Info';
import AutorenewIcon from '@mui/icons-material/Autorenew';
import DoneIcon from '@mui/icons-material/Done';

function EditStatus(props: GridRenderEditCellParams<string>) {
    const { id, value, field } = props;

    const apiRef = useGridApiContext();

    const handleChange: SelectProps['onChange'] = (event) => {
        apiRef.current.setEditCellValue(
            { id, field, value: event.target.value as any },
            event,
        );
        apiRef.current.commitCellChange({ id, field });
        apiRef.current.setCellMode(id, field, 'view');

        if ((event as any).key) {
            // TODO v6: remove once we stop ignoring events fired from portals
            const params = apiRef.current.getCellParams(id, field);
            apiRef.current.publishEvent(
                'cellNavigationKeyDown',
                params,
                event as any as React.KeyboardEvent<HTMLElement>,
            );
        }
    };

    const handleClose: MenuProps['onClose'] = (event, reason) => {
        if (reason === 'backdropClick') {
            apiRef.current.setCellMode(id, field, 'view');
        }
    };

    return (
        <Select
            value={value}
            onChange={handleChange}
            MenuProps={{
                onClose: handleClose,
            }}
            sx={{
                height: '100%',
                '& .MuiSelect-select': {
                    display: 'flex',
                    alignItems: 'center',
                    pl: 1,
                },
            }}
            autoFocus
            fullWidth
            open
        >
            {['Open', 'PartiallyFilled', 'Filled', 'Rejected'].map((option) => {
                let IconComponent: any = null;
                if (option === 'Rejected') {
                    IconComponent = ReportProblemIcon;
                } else if (option === 'Open') {
                    IconComponent = InfoIcon;
                } else if (option === 'PartiallyFilled') {
                    IconComponent = AutorenewIcon;
                } else if (option === 'Filled') {
                    IconComponent = DoneIcon;
                }

                let label = option;
                if (option === 'PartiallyFilled') {
                    label = 'Partially Filled';
                }

                return (
                    <MenuItem key={option} value={option}>
                        <ListItemIcon sx={{ minWidth: 36 }}>
                            <IconComponent fontSize="small" />
                        </ListItemIcon>
                        <ListItemText
                            primary={label}
                            sx={{ overflow: 'hidden' }}
                        />
                    </MenuItem>
                );
            })}
        </Select>
    );
}

export function renderEditStatus(params: GridRenderEditCellParams<string>) {
    return <EditStatus {...params} />;
}
