import { Auth, getAuth, connectAuthEmulator } from 'firebase/auth';
import { FirebaseApp, initializeApp } from 'firebase/app';
import { getStorage } from 'firebase/storage';
import { getFirestore, connectFirestoreEmulator } from 'firebase/firestore';
import { getAnalytics } from "firebase/analytics";
import { getFunctions, connectFunctionsEmulator, httpsCallable } from "firebase/functions";
import { getRemoteConfig } from "firebase/remote-config";

const firebaseApp: FirebaseApp = initializeApp({
    apiKey: 'AIzaSyASyfifg4f6SeSNeL3lvEHUO8yaNHTQ07I',
    authDomain: 'window-expo-dev.firebaseapp.com',    
    projectId: 'window-expo-dev',
    storageBucket: 'window-expo-dev.appspot.com',
    messagingSenderId: '940812487785',
    appId: '1:940812487785:web:6fcb6463b00fe2a228e6f8',
    measurementId: 'G-J32067871J'
});

export default firebaseApp;

export const auth: Auth = getAuth(firebaseApp);
export const storage = getStorage(firebaseApp);
export const firestore = getFirestore(firebaseApp);
export const analytics = getAnalytics(firebaseApp);
export const firebaseFunctions = getFunctions(firebaseApp);
export const firebaseRemoteConfig = getRemoteConfig(firebaseApp);

export const createUser = httpsCallable(firebaseFunctions, 'createUser');

// connectFunctionsEmulator(firebaseFunctions, "localhost", 5001);
// connectFirestoreEmulator(firestore, 'localhost', 8081);
// connectAuthEmulator(auth, "http://localhost:9099");