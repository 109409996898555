import React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { NavLink, useNavigate } from 'react-router-dom';
import { Avatar, ListItemIcon } from '@mui/material';
import { useAuth } from 'context/AuthContext';
import Logout from '@mui/icons-material/Logout';

interface Props {
    onSidebarOpen?: () => void;
}

const Topbar = ({ onSidebarOpen }: Props): JSX.Element => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event): void => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = (): void => {
        setAnchorEl(null);
    };

    const { currentUser, signout } = useAuth();

    const navigate = useNavigate();

    const signOutAndRedirect = () => {
        signout();
        navigate('/signin');
    }

    return (
        <Box
            display={'flex'}
            justifyContent={'space-between'}
            alignItems={'center'}
            width={1}
        >
            <Box display={'flex'} alignItems={'center'} color={'primary.dark'} onClick={() => navigate('/')}>
                <Button>
                    <Typography fontWeight={700} marginLeft={1}>
                        Shutters By Expo
                    </Typography>
                </Button>
            </Box>
            <Box display={'flex'} alignItems={'center'}>
                <Box
                    sx={{ display: { xs: 'block', md: 'none' } }}
                    alignItems={'center'}
                ></Box>
                <Box marginLeft={3}>
                    {!currentUser ? (
                        <NavLink
                            to="/signin"
                            style={{ textDecoration: 'none' }}
                        >
                            <Button
                                variant="contained"
                                color="primary"
                                component="a"
                                target="blank"
                                size="large"
                            >
                                Sign In
                            </Button>
                        </NavLink>
                    ) : (
                        <React.Fragment>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    textAlign: 'center',
                                }}
                            >
                                <Tooltip title="Account settings">
                                    <IconButton
                                        onClick={handleClick}
                                        size="small"
                                        sx={{ ml: 2 }}
                                        aria-controls={
                                            open ? 'account-menu' : undefined
                                        }
                                        aria-haspopup="true"
                                        aria-expanded={
                                            open ? 'true' : undefined
                                        }
                                    >
                                        <Avatar sx={{ width: 32, height: 32 }}>
                                            M
                                        </Avatar>
                                    </IconButton>
                                </Tooltip>
                            </Box>
                            <Menu
                                anchorEl={anchorEl}
                                id="account-menu"
                                open={open}
                                onClose={handleClose}
                                onClick={handleClose}
                                PaperProps={{
                                    elevation: 0,
                                    sx: {
                                        overflow: 'visible',
                                        filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                                        mt: 1.5,
                                        '& .MuiAvatar-root': {
                                            width: 32,
                                            height: 32,
                                            ml: -0.5,
                                            mr: 1,
                                        },
                                        '&:before': {
                                            content: '""',
                                            display: 'block',
                                            position: 'absolute',
                                            top: 0,
                                            right: 14,
                                            width: 10,
                                            height: 10,
                                            bgcolor: 'background.paper',
                                            transform:
                                                'translateY(-50%) rotate(45deg)',
                                            zIndex: 0,
                                        },
                                    },
                                }}
                                transformOrigin={{
                                    horizontal: 'right',
                                    vertical: 'top',
                                }}
                                anchorOrigin={{
                                    horizontal: 'right',
                                    vertical: 'bottom',
                                }}
                            >
                                <MenuItem onClick={signOutAndRedirect}>
                                    <ListItemIcon>
                                        <Logout fontSize="small" />
                                    </ListItemIcon>
                                    Logout
                                </MenuItem>
                            </Menu>
                        </React.Fragment>
                    )}
                </Box>
            </Box>
        </Box>
    );
};

export default Topbar;
