import Order, { Dealer } from 'types/Order';
import { v4 as uuidv4 } from 'uuid';
import Window from '../types/Window';

export type OrderAction =
    | { type: 'SET_DEALER'; payload: { value: Dealer } }
    | { type: 'SET_DEALER_ID'; payload: { value: string } }
    | { type: 'SET_JOB'; payload: { value: string } }
    | { type: 'SET_SIDEMARKS'; payload: { value: string } }
    | { type: 'SET_COLOR'; payload: { value: string } }
    | { type: 'SET_DATE'; payload: { value: string } }
    | { type: 'SET_MEASUREDBY'; payload: { value: string } }
    | { type: 'SET_DISTRIBUTORID'; payload: { value: string } }
    | { type: 'SET_ORDERID'; payload: { value: string } }
    | { type: 'ADD_WINDOW'; payload: { value: Window } }
    | { type: 'DELETE_WINDOW'; payload: { value: string } }
    | { type: 'EDIT_WINDOW'; payload: { value: Window } };

export const orderReducer = (
    state: Order,
    { type, payload }: OrderAction,
): Order => {
    switch (type) {
        case 'SET_DEALER':
            return { ...state, dealer: { ...(payload.value as Dealer) } };
        case 'SET_DEALER_ID':
            return { ...state, dealerId: payload.value as string };
        case 'SET_JOB':
            return { ...state, job: payload.value as string };
        case 'SET_SIDEMARKS':
            return { ...state, sideMarks: payload.value as string };
        case 'SET_COLOR':
            return { ...state, color: payload.value as string };
        case 'SET_MEASUREDBY':
            return { ...state, measuredBy: payload.value as string };
        case 'SET_DATE':
            return { ...state, date: payload.value as string };
        // case 'SET_DISTRIBUTORID':
        //     return { ...state, distributorId: payload.value as string };
        case 'SET_ORDERID':
            return { ...state, id: payload.value as string };
        case 'ADD_WINDOW':
            return {
                ...state,
                windows: [...state.windows, payload.value as Window],
            };
        case 'DELETE_WINDOW':
            return {
                ...state,
                windows: [
                    ...state.windows.filter(({ id }) => id !== payload.value),
                ],
            };
        case 'EDIT_WINDOW':
            return {
                ...state,
                windows: state.windows.map((window) => {
                    if (window.id === (payload.value as Window).id) {
                        return { ...(payload.value as Window) };
                    } else {
                        return { ...window };
                    }
                }),
            };
        default:
            return state;
    }
};
const generateRowData = (numberOfRow: number) => {
    const result = [];
    for (let i = 0; i < numberOfRow; i++) {
        result.push({
            id: uuidv4(),
            width: 0,
            height: 0,
            louverSize: 3,
            panels: 2,
            divRail: 0,
        });
    }

    return result;
};

export const rowsData: Window[] = generateRowData(1);

export const numberOfPanelOptions = [
    { name: '1', id: 1 },
    { name: '2', id: 2 },
    { name: '3', id: 3 },
    { name: '4', id: 4 },
    { name: '6', id: 6 },
];

export const panelDesigns = {
    1: [
        { name: 'R', id: 1, value: 0.1875 },
        { name: 'L', id: 2, value: 0.1875 },
    ],
    2: [
        { name: 'LR', id: 1, value: 0.375 },
        { name: 'LTR', id: 2, value: 1.625 },
        { name: 'LL', id: 3, value: 0.375 },
    ],
    3: [
        { name: 'RTRTR', id: 1, value: 3.0625 },
        { name: 'LLTR', id: 2, value: 1.8125 },
        { name: 'LRR', id: 3, value: 0.5 },
    ],
    4: [
        { name: 'LL-RR', id: 1, value: 0.625 },
        { name: 'LL-T-RR', id: 2, value: 2 },
        { name: 'LTL-T-RTR', id: 3, value: 4.5 },
        { name: 'RTRTRTR', id: 4, value: 4.5 },
    ],
    6: [
        { name: 'LTLL-RRTR', id: 1, value: 3.5 },
        { name: 'LTLL-T-RRTR', id: 2, value: 4.875 },
        { name: 'LTLTL-T-RTRTR', id: 3, value: 7.375 },
    ],
};

export const frameKinds = [
    { name: 'New Decor Z - 3Z/1S', id: 100 },
    { name: 'New Decor Z - 2Z/2S', id: 200 },
    { name: 'New Decor Z - 3S/1Z', id: 300 },
    { name: 'New Decor Z - 4Z', id: 400 },
    { name: 'OB Frame - 3OB/1S', id: 500 },
    { name: 'OB Frame - 2OB/2S', id: 600 },
    { name: 'OB Frame Tip to Tip - 4OB', id: 700 },
    { name: 'Sill Frame IB - 4S', id: 800 },
    { name: 'Sill Frame Tip to Tip - 4S', id: 900 },
];

export const frameDesignOptions = {
    100: [
        {
            name: 'TOP: Z; BOTTOM: S; LEFT: Z; RIGHT: Z',
            id: 1,
        },
        {
            name: 'TOP: S; BOTTOM: Z; LEFT: Z; RIGHT: Z',
            id: 2,
        },
        {
            name: 'TOP: Z; BOTTOM: Z; LEFT: S; RIGHT: Z',
            id: 3,
        },
        {
            name: 'TOP: Z; BOTTOM: Z; LEFT: Z; RIGHT: S',
            id: 4,
        },
    ],
    200: [
        {
            name: 'TOP: Z; BOTTOM: Z; LEFT: S; RIGHT: S',
            id: 1,
        },
        {
            name: 'TOP: Z; BOTTOM: S; LEFT: Z; RIGHT: S',
            id: 2,
        },
        {
            name: 'TOP: Z; BOTTOM: S; LEFT: S; RIGHT: Z',
            id: 3,
        },
        {
            name: 'TOP: S; BOTTOM: Z; LEFT: Z; RIGHT: S',
            id: 4,
        },
        {
            name: 'TOP: S; BOTTOM: S; LEFT: Z; RIGHT: Z',
            id: 5,
        },
        {
            name: 'TOP: S; BOTTOM: Z; LEFT: S; RIGHT: Z',
            id: 6,
        },
    ],
    300: [
        {
            name: 'TOP: S; BOTTOM: S; LEFT: S; RIGHT: Z',
            id: 1,
        },
        {
            name: 'TOP: Z; BOTTOM: S; LEFT: S; RIGHT: S',
            id: 2,
        },
        {
            name: 'TOP: S; BOTTOM: S; LEFT: Z; RIGHT: S',
            id: 3,
        },
        {
            name: 'TOP: S; BOTTOM: Z; LEFT: S; RIGHT: S',
            id: 4,
        },
    ],
    400: [
        {
            name: 'TOP: Z; BOTTOM: Z; LEFT: Z; RIGHT: Z',
            id: 1,
        },
    ],
    500: [
        {
            name: 'TOP: OB; BOTTOM: OB; LEFT: OB; RIGHT: S',
            id: 1,
        },
        {
            name: 'TOP: OB; BOTTOM: S; LEFT: OB; RIGHT: OB',
            id: 2,
        },
        {
            name: 'TOP: OB; BOTTOM: OB; LEFT: S; RIGHT: OB',
            id: 3,
        },
        {
            name: 'TOP: S; BOTTOM: OB; LEFT: OB; RIGHT: OB',
            id: 4,
        },
    ],
    600: [
        {
            name: 'TOP: OB; BOTTOM: OB; LEFT: S; RIGHT: S',
            id: 1,
        },
        {
            name: 'TOP: OB; BOTTOM: S; LEFT: OB; RIGHT: S',
            id: 2,
        },
        {
            name: 'TOP: OB; BOTTOM: S; LEFT: S; RIGHT: OB',
            id: 3,
        },
        {
            name: 'TOP: S; BOTTOM: OB; LEFT: OB; RIGHT: S',
            id: 4,
        },
        {
            name: 'TOP: S; BOTTOM: S; LEFT: OB; RIGHT: OB',
            id: 5,
        },
        {
            name: 'TOP: S; BOTTOM: OB; LEFT: S; RIGHT: OB',
            id: 6,
        },
    ],
    700: [
        {
            name: 'TOP: OB; BOTTOM: OB; LEFT: OB; RIGHT: OB',
            id: 1,
        },
    ],
    800: [
        {
            name: 'TOP: S; BOTTOM: S; LEFT: S; RIGHT: S',
            id: 1,
        },
    ],
    900: [
        {
            name: 'TOP: S; BOTTOM: S; LEFT: S; RIGHT: S',
            id: 1,
        },
    ],
};

export const LIST_IMAGES_LOCATION = [
    'TOPOBRIGHTOBBOTTOMOBLEFTS',
    'TOPOBRIGHTOBBOTTOMSLEFTOB',
    'TOPOBRIGHTOBBOTTOMSLEFTS',
    'TOPOBRIGHTSBOTTOMOBLEFTOB',
    'TOPOBRIGHTSBOTTOMOBLEFTS',
    'TOPOBRIGHTSBOTTOMSLEFTOB',
    'TOPOBRIGHTSBOTTOMSLEFTS',
    'TOPSRIGHTOBBOTTOMOBLEFTOB',
    'TOPSRIGHTOBBOTTOMOBLEFTS',
    'TOPSRIGHTOBBOTTOMSLEFTOB',
    'TOPSRIGHTOBBOTTOMSLEFTS',
    'TOPSRIGHTSBOTTOMOBLEFTOB',
    'TOPSRIGHTSBOTTOMOBLEFTS',
    'TOPSRIGHTSBOTTOMSLEFTOB',
    'TOPSRIGHTSBOTTOMSLEFTZ',
    'TOPSRIGHTSBOTTOMZLEFTS',
    'TOPSRIGHTSBOTTOMZLEFTZ',
    'TOPSRIGHTZBOTTOMSLEFTS',
    'TOPSRIGHTZBOTTOMSLEFTZ',
    'TOPSRIGHTZBOTTOMZLEFTS',
    'TOPSRIGHTZBOTTOMZLEFTZ',
    'TOPZRIGHTSBOTTOMSLEFTS',
    'TOPZRIGHTSBOTTOMSLEFTZ',
    'TOPZRIGHTSBOTTOMZLEFTS',
    'TOPZRIGHTSBOTTOMZLEFTZ',
    'TOPZRIGHTZBOTTOMSLEFTS',
    'TOPZRIGHTZBOTTOMSLEFTZ',
    'TOPZRIGHTZBOTTOMZLEFTS',
    'TOPZRIGHTZBOTTOMZLEFTZ',
    'TOPOBRIGHTOBBOTTOMOBLEFTOB',
    'TOPSRIGHTSBOTTOMSLEFTS',
];
