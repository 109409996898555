import {
    addDoc,
    collection,
    deleteDoc,
    doc,
    DocumentData,
    getCountFromServer,
    getDoc,
    getDocs,
    or,
    query,
    setDoc,
    updateDoc,
    where,
} from 'firebase/firestore';
import Distributor from 'types/Distributor';
import Order, { OrderType } from 'types/Order';
import { firestore } from './firebaseSetUp/firebaseSetUp';
import User, { AccountType } from 'types/User';
import { PurchaseOrder } from 'pages/PurchaseOrder/PurchaseOrderComponent';

export default class DashboardManager {
    static async getOrdersForUser(userId: string, orderType = OrderType.WINDOW): Promise<Array<Order>> {
        const ordersByUserId = query(
            collection(firestore, 'orders'),
            where('userId', '==', userId),
            where('orderType', '==', orderType)
        );
        const orders = await getDocs(ordersByUserId);
        
        if (!orders) return [];

        const user = await this.getUserMetaData(userId);

        const notesList = orders.docs.map((doc): Order => {
            return {
                ...(doc.data() as Order),
                id: doc.id,
                orderOwnerName: user.name
            };
        });
        return notesList;
    }

    static async getOrdersForUserAndChildren(userId: string, orderType = OrderType.WINDOW): Promise<Array<Order>> {
        const userAndChildren = query(
            collection(firestore, 'users'),
            or(
                where('userId', '==', userId),
                where('parentId', '==', userId)
            ));

        const allUsers = await getDocs(userAndChildren);
        const allUserIds = allUsers.docs.map((doc): User => {
            return {
                ...(doc.data() as User),
                id: doc.id,
            };
        });

        if (allUserIds.length === 0) return [];

        const ordersByUserIdAndChildren = query(
            collection(firestore, 'orders'),
            where('orderType', '==', orderType),
            where('userId', 'in', allUserIds.map(e => e.id)),
        );

        const orders = await getDocs(ordersByUserIdAndChildren);
        
        const allOrders = orders.docs.map((doc): Order => {
            return {
                ...(doc.data() as Order),
                id: doc.id,
                orderOwnerName: allUserIds.find((e) => doc.data().userId === e.id)?.name
            };
        });

        return allOrders;
    }

    static async getOrdersForDistributor(
        userId: string,
    ): Promise<Array<Order>> {
        // First get all users under this distributor
        const distributorsQuery = query(
            collection(firestore, 'users'),
            where('distributorId', '==', userId),
        );

        const distributors = (await getDocs(distributorsQuery)).docs.map(
            (doc: DocumentData): Distributor => {
                return doc.data();
            },
        );

        // Go through all the users under this current distributor, get orders
        // that tie to that user and add that to result;
        let result: Order[] = [];

        for (const distributor of distributors) {
            const userId = distributor.id;
            const distributorOrders = await this.getOrdersForUser(userId);
            result = [...result, ...distributorOrders];
        }
        return result;
    }

    static async getAccounts(currentUserId: string, childAccountType: AccountType): Promise<Array<any>> {
        const accountsQuery = query(
            collection(firestore, 'users'),
            where('parentId', '==', currentUserId),
            where('accountType', '==', childAccountType),
        );

        const accounts = (await getDocs(accountsQuery)).docs.map(
            (doc: DocumentData): Distributor => {
                return { ...doc.data(), id: doc.id };
            },
        );

        return accounts;    
    }

    static async getDistributors(): Promise<Array<Distributor>> {
        const distributorsByUserId = query(
            collection(firestore, 'users'),
            where('accountType', '==', AccountType.DISTRIBUTOR),
        );
        const distributors = await getDocs(distributorsByUserId);
        const notesList = distributors.docs.map(
            (doc: DocumentData): Distributor => {
                return {
                    ...doc.data(),
                };
            },
        );
        return notesList;
    }

    static async getDistributor(userId: string): Promise<Distributor | null> {
        const userRef = doc(firestore, 'users', userId);
        const user = await getDoc(userRef);

        if (user.exists()) {
            const distributorDoc = doc(firestore, 'users', user.data().parentId);
            const distributor = await getDoc(distributorDoc);
            if (distributor.exists() && distributor.data().accountType === AccountType.DISTRIBUTOR) {
                return distributor.data() as Distributor;
            } else {
                return null;
            }
        } else {
            console.log('No such document!');
        }
    }

    static async getParent(userId: string): Promise<User> {
        const userRef = doc(firestore, 'users', userId);
        const user = await getDoc(userRef);

        if (user.exists()) {
            const parentDoc = doc(firestore, 'users', user.data().parentId);
            const parent = await getDoc(parentDoc);
            if (parent.exists()) {
                return parent.data() as User;
            }
        } else {
            console.log('No such document!');
        }
    }

    static async getUserMetaData(userId: string): Promise<DocumentData> {
        const userRef = doc(firestore, 'users', userId);
        const user = await getDoc(userRef);

        if (user.exists()) {
            return user.data();
        } else {
            console.log('No such document!');
        }
    }

    static async saveStatus(orderId: string, status) {
        return await setDoc(doc(firestore, 'ordersStatus', orderId), status);
    }

    static async saveOrder(order: any) {
        const orderNumber = await this.getNextOrderCounter();
        return await addDoc(collection(firestore, 'orders'), {...order, orderNumber, createdAt: new Date().toISOString()});
    }

    static async updatePurchaseOrder(order: PurchaseOrder, orderId: string) {
        return await updateDoc(doc(firestore, 'orders', orderId), {...order});
    }

    static async updateAccount(id, userInfo) {
        return await updateDoc(doc(firestore, 'users', id), {...userInfo, updatedAt: new Date().toISOString()});
    }

    static async getNextOrderCounter() {
        const coll = collection(firestore, "orders");
        const snapshot = await getCountFromServer(coll);
        return snapshot.data().count + 1;
    }

    static async deleteDealer(id) {
        return await deleteDoc(doc(firestore, 'dealers', id));
    }

    static async getDealers(parentId) {
        const dealersQuery = query(
            collection(firestore, 'users'),
            where('parentId', '==', parentId),
            where('accountType', '==', AccountType.DEALER),
        );

        const dealers = (await getDocs(dealersQuery)).docs.map(
            (doc: DocumentData): Distributor => {
                return { ...doc.data(), id: doc.id };
            },
        );
        return dealers;
    }

    static async getFabricators(parentId) {
        const fabricatorsQuery = query(
            collection(firestore, 'users'),
            where('parentId', '==', parentId),
            where('accountType', '==', AccountType.FABRICATOR),
        );

        const dealers = (await getDocs(fabricatorsQuery)).docs.map(
            (doc: DocumentData): Distributor => {
                return { ...doc.data(), id: doc.id };
            },
        );
        return dealers;
    }

    static async deleteFabricator(id) {
        return await setDoc(doc(firestore, 'users', id), {active: false});
    }

    static async updateFabricator(fabricatorId, fabricatorInfo) {
        return await setDoc(doc(firestore, 'users', fabricatorId), fabricatorInfo);
    }

    static async getStatus(orderNumber: string): Promise<any> {
        const statusRef = doc(firestore, 'ordersStatus', String(orderNumber));
        const status = await getDoc(statusRef);

        if (status.exists()) {
            return status.data();
        } else {
            console.log('No such document!');
        }
    }

    static async logError(errors: any): Promise<any> {
        return await addDoc(collection(firestore, 'errors'), {...errors, createdAt: new Date().toISOString()});
    }

}
