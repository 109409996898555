import Window from 'types/Window';

class WindowUtils {
    static isValidWindow = (window: Window) => {
        return !!(
            window.id &&
            window.width &&
            window.height &&
            window.frameKind &&
            window.frameDesign &&
            window.panelDesign
        );
    };
}

export default WindowUtils;
