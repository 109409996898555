import { Grid, TextField, useTheme } from '@mui/material';
import React from 'react';
import { TotalOrderMaterial } from 'types/Order';

type TotalMaterialProps = {
    totalMaterial: TotalOrderMaterial
}

const nameMapping = {
  totalTPodMaterial: 'Total TPod Material',
  totalSQMaterial: 'Total SQ Material',
  totalLouverMaterial: 'Total Louver Material',
  totalTopRailWidthMaterial: 'Total Top Rail Width Material',
  totalBottomRailWidthMaterial: 'Total Bottom Rail Width Material',
  totalStileMaterial: 'Total Stile Material',
  totalCutAluminumMaterial: 'Total Cut Aluminum Material',
  totalMagicTiltMaterial: 'Total Magic Tilt Material',
  totalFiveInchMaterial: 'Total 5" Material',
  totalThreeInchMaterial: 'Total 3" Material',
  Z: 'Total Z',
  S: 'Total S',
  OB: 'Total OB'
}

const TotalMaterialComponent : React.FC<TotalMaterialProps> = ({totalMaterial}): JSX.Element  => {
    const theme = useTheme();

    const renderTextFields = () => {
      const textFields = [];
  
      for (const key in totalMaterial) {
        if (typeof totalMaterial[key] === 'number') {
          textFields.push(
            <Grid item key={key} xs={1} md={1} xl={2}>
                <TextField
                    fullWidth
                    key={key}
                    id={key}
                    label={nameMapping[key]}
                    value={`${totalMaterial[key]} ft`}
                    variant="standard"
                    size="small"
                    InputProps={{
                        readOnly: true,
                        style: {
                        fontSize: '12px',
                        color: theme.palette.text.secondary,
                        backgroundColor: theme.palette.background.default,
                        borderColor: theme.palette.divider,
                        },
                    }}
                />
            </Grid>
          );
        }
      }

      return textFields;
    };
  
    return (
        <Grid container spacing={2}>
          {renderTextFields()}
        </Grid>   
    );
}

export default TotalMaterialComponent;